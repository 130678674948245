module.exports = {
  'african-grey-parrot': 'perroquet-gris-africain',
  alligator: 'alligator',
  alpaca: 'alpaga',
  anteater: 'fourmilier',
  antelope: 'antilope',
  ape: 'singe',
  bat: 'chauve-souris',
  bee: 'abeille',
  'bowhead-whale': 'baleine boréale',
  buffalo: 'buffle',
  butterfly: 'papillon',
  camel: 'chameau',
  cat: 'chat',
  chicken: 'poulet',
  cow: 'vache',
  crow: 'corbeau',
  dinosaur: 'dinosaure',
  dog: 'chien',
  dove: 'colombe',
  duck: 'canard',
  elephant: 'éléphant',
  falcon: 'faucon',
  ferret: 'furet',
  frog: 'grenouille ',
  giraffe: 'girafe',
  'guinea-pig': "cochon d'inde",
  hedgehog: 'hérisson',
  hippopotamus: 'hippopotame',
  horse: 'cheval',
  'humpback-whale': 'baleine à bosse',
  hyena: 'hyène',
  'komodo-dragon': 'dragon de Komodo',
  leopard: 'léopard',
  lion: 'lion',
  lizard: 'lézard ',
  moose: 'orignal',
  otter: 'loutre',
  owl: 'hibou',
  panda: 'panda',
  penguin: 'pingouin',
  pig: 'cochon',
  rabbit: 'lapin',
  raccoon: 'raton laveur',
  rat: 'rat',
  rattlesnake: 'crotale',
  rhinoceros: 'rhinocéros',
  robin: 'robin',
  rooster: 'coq',
  scorpion: 'scorpion',
  shark: 'requin',
  sheep: 'mouton',
  swan: 'cygne',
  tiger: 'tigre',
  turkey: 'dinde',
  wolf: 'loup',
  yak: 'yak',
  zebra: 'zèbre',
};
