module.exports = {
  'african-grey-parrot': "Afrika-kulrang-to'tiqush",
  alligator: 'alligator',
  alpaca: 'alpaka',
  anteater: 'chumolilar',
  antelope: 'antilopalar',
  ape: 'maymunlar',
  bat: "ko'rshapalak",
  bee: 'ari',
  'bowhead-whale': 'kamonli kit',
  buffalo: 'Bufalo',
  butterfly: 'kelebek',
  camel: 'tuya',
  cat: 'mushuk',
  chicken: 'tovuq',
  cow: 'sigir',
  crow: "qarg'a",
  dinosaur: 'dinozavr',
  dog: 'it',
  dove: 'kaptar',
  duck: "o'rdak",
  elephant: 'fil',
  falcon: 'lochin',
  ferret: 'ferret',
  frog: 'qurbaqa',
  giraffe: 'jirafa',
  'guinea-pig': "gvineya-cho'chqa",
  hedgehog: 'kirpi',
  hippopotamus: 'begemot',
  horse: 'ot',
  'humpback-whale': 'kambur-kit',
  hyena: 'gyena',
  'komodo-dragon': 'komodo ajdaho',
  leopard: 'leopard',
  lion: 'sher',
  lizard: 'kertenkele',
  moose: "bo'ri",
  otter: 'otter',
  owl: "boyo'g'li",
  panda: 'panda',
  penguin: 'pingvin',
  pig: "cho'chqa",
  rabbit: 'quyon',
  raccoon: 'rakun',
  rat: 'kalamush',
  rattlesnake: 'shovqinli ilon',
  rhinoceros: 'karkidon',
  robin: 'robin',
  rooster: "xo'roz",
  scorpion: 'chayon',
  shark: 'akula',
  sheep: "qo'y",
  swan: 'oqqush',
  tiger: "yo'lbars",
  turkey: 'kurka',
  wolf: "bo'ri",
  yak: 'yak',
  zebra: 'zebra',
};
