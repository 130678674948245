<template>
  <div class="p-4 text-center text-white">
    <h1>
      <slot name="title" />
    </h1>
    <h2 class="pt-4" :class="{invisible: invisible}" data-test-id="game_headline__subtitle">
      <slot name="subtitle" />
    </h2>
    <slot name="hint" />
  </div>
</template>

<script>
export default {
  name: 'GameHeadline',
  props: {
    invisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
