module.exports = {
  'african-grey-parrot': 'طوطی آفریقایی خاکستری',
  alligator: 'تمساح',
  alpaca: 'آلپاکا',
  anteater: 'مورچه خوار',
  antelope: 'آنتلوپ',
  ape: 'میمون',
  bat: 'خفاش',
  bee: 'زنبور عسل',
  'bowhead-whale': 'نهنگ سر',
  buffalo: 'بوفالو',
  butterfly: 'پروانه',
  camel: 'شتر',
  cat: 'گربه',
  chicken: 'مرغ',
  cow: 'گاو',
  crow: 'کلاغ',
  dinosaur: 'دایناسور',
  dog: 'سگ',
  dove: 'کبوتر',
  duck: 'اردک',
  elephant: 'فیل',
  falcon: 'شاهین',
  ferret: 'فرت',
  frog: 'قورباغه',
  giraffe: 'زرافه',
  'guinea-pig': 'خوکچه هندی',
  hedgehog: 'خارپشت',
  hippopotamus: 'اسب آبی',
  horse: 'اسب',
  'humpback-whale': 'نهنگ کوهان دار',
  hyena: 'کفتار',
  'komodo-dragon': 'کومودو-اژدها',
  leopard: 'پلنگ',
  lion: 'شیر',
  lizard: 'مارمولک',
  moose: 'گوزن ماهی',
  otter: 'سمور',
  owl: 'جغد',
  panda: 'پاندا',
  penguin: 'پنگوئن',
  pig: 'خوک',
  rabbit: 'خرگوش',
  raccoon: 'راکون',
  rat: 'موش',
  rattlesnake: 'مار زنگی',
  rhinoceros: 'کرگدن',
  robin: 'رابین',
  rooster: 'خروس',
  scorpion: 'عقرب',
  shark: 'کوسه',
  sheep: 'گوسفند',
  swan: 'قو',
  tiger: 'ببر',
  turkey: 'بوقلمون',
  wolf: 'گرگ',
  yak: 'یاک',
  zebra: 'گورخر',
};
