module.exports = {
  'african-grey-parrot': 'parot african-llwyd-parot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelop',
  ape: 'ape',
  bat: 'ystlum',
  bee: 'gwenyn',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'byfflo',
  butterfly: 'pili pala',
  camel: 'camel',
  cat: 'cath',
  chicken: 'cyw iâr',
  cow: 'buwch',
  crow: 'frân',
  dinosaur: 'deinosor',
  dog: 'ci',
  dove: 'colomen',
  duck: 'hwyaden',
  elephant: 'eliffant',
  falcon: 'hebog',
  ferret: 'ffured',
  frog: 'broga',
  giraffe: 'jiraff',
  'guinea-pig': 'mochyn cwta',
  hedgehog: 'draenog',
  hippopotamus: 'hippopotamus',
  horse: 'ceffyl',
  'humpback-whale': 'humpback-whale',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'llewpard',
  lion: 'llew',
  lizard: 'madfall',
  moose: 'moose',
  otter: 'dyfrgi',
  owl: 'tylluan',
  panda: 'panda',
  penguin: 'pengwin',
  pig: 'mochyn',
  rabbit: 'cwningen',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'rhinoseros',
  robin: 'robin goch',
  rooster: 'ceiliog',
  scorpion: 'sgorpion',
  shark: 'siarc',
  sheep: 'defaid',
  swan: 'alarch',
  tiger: 'teigr',
  turkey: 'twrci',
  wolf: 'blaidd',
  yak: 'iac',
  zebra: 'sebra',
};
