<template>
  <div class="cursor-pointer">
    <img
      class="w-full max-h-8"
      :src="img.src" :alt="img.alt" :title="img.title"
      data-test-id="flag__img">
    <div
      class="text-white text-center pt-1"
      data-test-id="flag__title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';

export default {
  name: 'Flag',
  props: {
    flag: {
      type: String,
      default: 'en',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const img = reactive({
      src: '',
      alt: '',
      title: '',
    });
    const { flag } = toRefs(props);

    watch(flag, () => {
      try {
        img.src = require(`@/assets/images/flags/${flag.value}.svg`);
      } catch {
        img.src = require(`@/assets/images/flags/question-mark.svg`);
      }
      img.alt = `Flag ${flag.value}`;
      img.title = `Flag ${flag.value}`;
    }, { immediate: true });

    return {
      img,
    };
  },
};
</script>

