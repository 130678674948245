<template>
  <carousel
    wrap-around
    v-model="initialSlide"
    class="py-8 bg-white shadow-lg rounded-lg m-8 sm:m-auto max-w-xl">
    <slide v-for="animal in animals" :key="animal.name">
      <div class="flex flex-col items-center">
        <Animal
          :animal="animal"
          :selectable="false"
          :highlightable="false"
          animal-name-text-color="text-black"
          show-name
          scale
          pulse-animation
          pulse-text-color="text-yellow-600" />
      </div>
    </slide>

    <template #addons="{ currentSlide, slidesCount }">
      <Navigation />

      <div class="pt-4 text-gray-500" data-test-id="animal_carousel__slide">
        {{ currentSlide + 1 }} / {{ slidesCount }}
      </div>
    </template>
  </carousel>
</template>

<script>
import Animal from '@/components/shared/animal';
import { ref, onMounted } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'AnimalCarousel',
  components: {
    Animal,
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    animals: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const initialSlide = ref(undefined);

    onMounted(() => {
      initialSlide.value = 0;
    });

    return {
      initialSlide,
    };
  },
};
</script>

<style>
.carousel__prev,
.carousel__next {
  background: #ffdc95;
}

.carousel button:focus {
  outline: none;
}
</style>
