import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import current from './modules/current';
import animalsInOrder from './modules/animalsInOrder';
import user from './modules/user';
import animalTranslations from './modules/animalTranslations';

const ls = new SecureLS({ isCompression: false });
const plugins = [];

plugins.push(
  createPersistedState({
    key: 'animal_games',
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
    // persist state only for modules in paths
    paths: [
      'user.game',
      'user.appLanguage',
      'user.animalLanguage',
    ],
  }),
);

if (process.env.NODE_ENV !== 'production') {
  plugins.push(createLogger());
}

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  plugins,
  modules: {
    current,
    animalsInOrder,
    user,
    animalTranslations,
  },
});
