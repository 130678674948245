module.exports = {
  'african-grey-parrot': 'Psittacus africanus',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteatrum',
  antelope: 'orygem',
  ape: 'similis',
  bat: 'vespertilio',
  bee: 'apis',
  'bowhead-whale': 'balaena',
  buffalo: 'bubalus',
  butterfly: 'papilio',
  camel: 'camelus',
  cat: 'feles',
  chicken: 'pullus',
  cow: 'vacca',
  crow: 'cornix',
  dinosaur: 'dinosaurus',
  dog: 'canis',
  dove: 'columba',
  duck: 'anatis',
  elephant: 'elephantis',
  falcon: 'falco',
  ferret: 'ferret',
  frog: 'rana',
  giraffe: 'panthera',
  'guinea-pig': 'Guinea porcus',
  hedgehog: 'erinaceus',
  hippopotamus: 'hippopotamo',
  horse: 'equi',
  'humpback-whale': 'gibbo',
  hyena: 'hyaena',
  'komodo-dragon': 'komodo draco',
  leopard: 'leopardus',
  lion: 'leo',
  lizard: 'lacerta',
  moose: 'alces',
  otter: 'luter',
  owl: 'noctua',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'porcus',
  rabbit: 'lepus',
  raccoon: 'lotos',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'Rhinoceros',
  robin: 'robin',
  rooster: 'gallus',
  scorpion: 'Scorpius',
  shark: 'turpis',
  sheep: 'ovis',
  swan: 'cycnus',
  tiger: 'tigris',
  turkey: 'turcia',
  wolf: 'lupus',
  yak: 'iocchus',
  zebra: 'zebra',
};
