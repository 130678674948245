module.exports = {
  'african-grey-parrot': 'αφρικανικός-γκρι-παπαγάλος',
  alligator: 'αλιγάτορας',
  alpaca: 'αλπάκα',
  anteater: 'μυρμηγκοφάγος',
  antelope: 'αντιλόπη',
  ape: 'πίθηκος',
  bat: 'νυχτερίδα',
  bee: 'μέλισσα',
  'bowhead-whale': 'φαλαινοκέφαλο',
  buffalo: 'βουβάλι',
  butterfly: 'πεταλούδα',
  camel: 'καμήλα',
  cat: 'γάτα',
  chicken: 'κοτόπουλο',
  cow: 'αγελάδα',
  crow: 'κοράκι',
  dinosaur: 'δεινόσαυρος',
  dog: 'σκύλος',
  dove: 'περιστέρι',
  duck: 'πάπια',
  elephant: 'ελέφαντας',
  falcon: 'γεράκι',
  ferret: 'κουνάβι',
  frog: 'βάτραχος',
  giraffe: 'καμηλοπάρδαλη',
  'guinea-pig': 'ινδικό χοιρίδιο',
  hedgehog: 'σκαντζόχοιρος',
  hippopotamus: 'ιπποπόταμος',
  horse: 'άλογο',
  'humpback-whale': 'καμπούρα-φάλαινα',
  hyena: 'ύαινα',
  'komodo-dragon': 'κομόντο-δράκος',
  leopard: 'λεοπάρδαλη',
  lion: 'λιοντάρι',
  lizard: 'σαύρα',
  moose: 'άλκος',
  otter: 'βίδρα',
  owl: 'κουκουβάγια',
  panda: 'panda',
  penguin: 'πιγκουίνος',
  pig: 'γουρούνι',
  rabbit: 'κουνέλι',
  raccoon: 'ρακούν',
  rat: 'αρουραίος',
  rattlesnake: 'κροταλία',
  rhinoceros: 'ρινόκερος',
  robin: 'ρόμπιν',
  rooster: 'κόκορας',
  scorpion: 'σκορπιός',
  shark: 'καρχαρίας',
  sheep: 'πρόβατο',
  swan: 'κύκνος',
  tiger: 'τίγρης',
  turkey: 'γαλοπούλα',
  wolf: 'λύκος',
  yak: 'γιακ',
  zebra: 'ζέβρα',
};
