module.exports = {
  'african-grey-parrot': 'Африк-саарал-тоть',
  alligator: 'матар',
  alpaca: 'альпака',
  anteater: 'шоргоолж',
  antelope: 'гөрөөс',
  ape: 'сармагчин',
  bat: 'сарьсан багваахай',
  bee: 'зөгий',
  'bowhead-whale': 'нум сумтай халим',
  buffalo: 'одос',
  butterfly: 'эрвээхэй',
  camel: 'тэмээ',
  cat: 'муур',
  chicken: 'тахиа',
  cow: 'үхэр',
  crow: 'хэрээ',
  dinosaur: 'үлэг гүрвэл',
  dog: 'нохой',
  dove: 'тагтаа',
  duck: 'нугас',
  elephant: 'заан',
  falcon: 'шонхор',
  ferret: 'гөрөөс',
  frog: 'мэлхий',
  giraffe: 'анааш',
  'guinea-pig': 'далайн гахай',
  hedgehog: 'зараа',
  hippopotamus: 'хиппопотамус',
  horse: 'морь',
  'humpback-whale': 'бөгсөн халим',
  hyena: 'эрлийз',
  'komodo-dragon': 'комодо-луу',
  leopard: 'ирвэс',
  lion: 'арслан',
  lizard: 'гүрвэл',
  moose: 'хандгай',
  otter: 'халиу',
  owl: 'шар шувуу',
  panda: 'панда',
  penguin: 'оцон шувуу',
  pig: 'гахай',
  rabbit: 'туулай',
  raccoon: 'элбэнх',
  rat: 'харх',
  rattlesnake: 'догшин могой',
  rhinoceros: 'хирс',
  robin: 'робин',
  rooster: 'азарган тахиа',
  scorpion: 'хилэнцэт хорхой',
  shark: 'акул',
  sheep: 'хонь',
  swan: 'хун',
  tiger: 'бар',
  turkey: 'цацагт хяруул',
  wolf: 'чоно',
  yak: 'сарлаг',
  zebra: 'зебра',
};
