import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/components/NotFound';
import { pathTo, createDynamicRoutes } from '@/plugins/router/helper';
import store from '@/plugins/store';


const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
  ...createDynamicRoutes(),
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isGameRunning = () => {
    const isGame = store.state.user.game;
    if (isGame === 'animals_in_order' || isGame === 'animals_in_order_blind') {
      return Object.values(store.state.animalsInOrder?.animalSelection || {}).length === 0;
    }
    if (isGame === 'animal_quiz') {
      return store.state.current.rounds === null || store.state.current.rounds === 0;
    }

    return false;
  };
  // __game, __start & __highscore are all subpages of each game
  const pageReload = to.name?.includes('__game') && !from.name;
  const browserBackwards = (to.name?.includes('__start') && from.name?.includes('__game')) || (to.name?.includes('__game') && from.name?.includes('__highscore'));
  const browserForwards = to.name?.includes('__game') && isGameRunning();

  if (pageReload) {
    next({ path: pathTo.home });
  } else if (browserBackwards) {
    window.location.reload();
  } else if (browserForwards) {
    next(false);
  } else {
    next();
  }
});

export default router;
