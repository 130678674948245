module.exports = {
  'african-grey-parrot': 'papuga afrykańska',
  alligator: 'aligator',
  alpaca: 'alpaka',
  anteater: 'mrówkojad',
  antelope: 'antylopa',
  ape: 'małpa',
  bat: 'nietoperz',
  bee: 'pszczoła',
  'bowhead-whale': 'wieloryb grenlandzki',
  buffalo: 'bawół',
  butterfly: 'motyl',
  camel: 'wielbłąd',
  cat: 'kot',
  chicken: 'kurczak',
  cow: 'krowa',
  crow: 'wrona',
  dinosaur: 'dinozaur',
  dog: 'pies',
  dove: 'gołąb',
  duck: 'kaczka',
  elephant: 'słoń',
  falcon: 'sokół',
  ferret: 'fretka',
  frog: 'żaba',
  giraffe: 'żyrafa',
  'guinea-pig': 'świnka morska',
  hedgehog: 'jeż',
  hippopotamus: 'hipopotam',
  horse: 'koń',
  'humpback-whale': 'humbak',
  hyena: 'hiena',
  'komodo-dragon': 'komodo-smok',
  leopard: 'leopard',
  lion: 'lew',
  lizard: 'jaszczurka',
  moose: 'łoś',
  otter: 'wydra',
  owl: 'sowa',
  panda: 'panda',
  penguin: 'pingwin',
  pig: 'świnia',
  rabbit: 'królik',
  raccoon: 'szop',
  rat: 'szczur',
  rattlesnake: 'grzechotnik',
  rhinoceros: 'nosorożec',
  robin: 'robin',
  rooster: 'kogut',
  scorpion: 'skorpion',
  shark: 'rekin',
  sheep: 'owca',
  swan: 'łabędź',
  tiger: 'tygrys',
  turkey: 'indyk',
  wolf: 'wilk',
  yak: 'jak',
  zebra: 'zebra',
};
