module.exports = {
  'african-grey-parrot': 'african-pelēks papagailis',
  alligator: 'aligators',
  alpaca: 'alpaka',
  anteater: 'skudru pūznis',
  antelope: 'antilope',
  ape: 'pērtiķis',
  bat: 'sikspārnis',
  bee: 'bite',
  'bowhead-whale': 'priekšgala valis',
  buffalo: 'bifeļi',
  butterfly: 'tauriņi',
  camel: 'kamieļi',
  cat: 'kaķis',
  chicken: 'vista',
  cow: 'govs',
  crow: 'vārna',
  dinosaur: 'dinozaurs',
  dog: 'suns',
  dove: 'balodis',
  duck: 'pīle',
  elephant: 'zilonis',
  falcon: 'piekūns',
  ferret: 'sesks',
  frog: 'varde',
  giraffe: 'žirafe',
  'guinea-pig': 'jūrascūciņa',
  hedgehog: 'ezis',
  hippopotamus: 'nīlzirgs',
  horse: 'zirgs',
  'humpback-whale': 'kuprītis-valis',
  hyena: 'hiēna',
  'komodo-dragon': 'komodo-pūķis',
  leopard: 'leopards',
  lion: 'lauva',
  lizard: 'ķirzaka',
  moose: 'aļņi',
  otter: 'ūdrs',
  owl: 'pūce',
  panda: 'panda',
  penguin: 'pingvīns',
  pig: 'cūka',
  rabbit: 'trusis',
  raccoon: 'jenots',
  rat: 'žurka',
  rattlesnake: 'grabulīšu čūska',
  rhinoceros: 'degunradzis',
  robin: 'robin',
  rooster: 'gailis',
  scorpion: 'skorpions',
  shark: 'haizivs',
  sheep: 'aita',
  swan: 'gulbis',
  tiger: 'tīģeris',
  turkey: 'tītars',
  wolf: 'vilks',
  yak: 'jaks',
  zebra: 'zebra',
};
