module.exports = {
  'african-grey-parrot': 'Afrika-boz-tutuquşu',
  alligator: 'timsah',
  alpaca: 'alpaka',
  anteater: 'qarışqa yeməyi',
  antelope: 'antilop',
  ape: 'meymun',
  bat: 'yarasa',
  bee: 'arı',
  'bowhead-whale': 'yay başı balina',
  buffalo: 'camış',
  butterfly: 'kəpənək',
  camel: 'dəvə',
  cat: 'pişik',
  chicken: 'toyuq',
  cow: 'inək',
  crow: 'qarğa',
  dinosaur: 'dinozavr',
  dog: 'it',
  dove: 'göyərçin',
  duck: 'ördək',
  elephant: 'fil',
  falcon: 'şahin',
  ferret: 'ferret',
  frog: 'qurbağa',
  giraffe: 'zürafə',
  'guinea-pig': 'qvineya donuzu',
  hedgehog: 'kirpi',
  hippopotamus: 'suaygırı',
  horse: 'at',
  'humpback-whale': 'kambur balina',
  hyena: 'sırtlan',
  'komodo-dragon': 'komodo-əjdaha',
  leopard: 'bəbir',
  lion: 'aslan',
  lizard: 'kərtənkələ',
  moose: 'geyik',
  otter: 'su samuru',
  owl: 'bayquş',
  panda: 'panda',
  penguin: 'pinqvin',
  pig: 'donuz',
  rabbit: 'dovşan',
  raccoon: 'yenot',
  rat: 'siçovul',
  rattlesnake: 'cırtdan ilan',
  rhinoceros: 'kərgədan',
  robin: 'robin',
  rooster: 'xoruz',
  scorpion: 'əqrəb',
  shark: 'köpək balığı',
  sheep: 'qoyun',
  swan: 'qu',
  tiger: 'pələng',
  turkey: 'hinduşka',
  wolf: 'canavar',
  yak: 'yak',
  zebra: 'zebra',
};
