module.exports = {
  'african-grey-parrot': 'afrískur-grár-páfagaukur',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'maurfiskur',
  antelope: 'antilope',
  ape: 'api',
  bat: 'kylfa',
  bee: 'býfluga',
  'bowhead-whale': 'boghvalur',
  buffalo: 'buffalo',
  butterfly: 'butterfly',
  camel: 'camel',
  cat: 'köttur',
  chicken: 'kjúklingur',
  cow: 'kýr',
  crow: 'kráka',
  dinosaur: 'risaeðla',
  dog: 'hundur',
  dove: 'dúfa',
  duck: 'önd',
  elephant: 'fíl',
  falcon: 'fálki',
  ferret: 'frettur',
  frog: 'froskur',
  giraffe: 'gíraffi',
  'guinea-pig': 'naggrís',
  hedgehog: 'broddgöltur',
  hippopotamus: 'flóðhestur',
  horse: 'hestur',
  'humpback-whale': 'hnúfubakur',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dreki',
  leopard: 'hlébarði',
  lion: 'ljón',
  lizard: 'eðla',
  moose: 'elg',
  otter: 'otur',
  owl: 'ugla',
  panda: 'panda',
  penguin: 'mörgæs',
  pig: 'svín',
  rabbit: 'kanína',
  raccoon: 'þvottabjörn',
  rat: 'rotta',
  rattlesnake: 'skröltormur',
  rhinoceros: 'nashyrningur',
  robin: 'rjúpna',
  rooster: 'hani',
  scorpion: 'sporðdreki',
  shark: 'hákarl',
  sheep: 'kindur',
  swan: 'svan',
  tiger: 'tígrisdýr',
  turkey: 'kalkúnn',
  wolf: 'úlfur',
  yak: 'yak',
  zebra: 'sebra',
};
