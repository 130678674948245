module.exports = {
  'african-grey-parrot': 'i-african-grey-upholi',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'i-anteater',
  antelope: 'i-antelope',
  ape: 'inkawu',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'inyathi',
  butterfly: 'uvemvane',
  camel: 'ikamela',
  cat: 'ikati',
  chicken: 'inkukhu',
  cow: 'inkomo',
  crow: 'igwababa',
  dinosaur: 'idayinaso',
  dog: 'inja',
  dove: 'ijuba',
  duck: 'idada',
  elephant: 'indlovu',
  falcon: 'ukhozi',
  ferret: 'ferret',
  frog: 'frog',
  giraffe: 'indlulamithi',
  'guinea-pig': 'i-Guinea yengulube',
  hedgehog: 'i-hedgehog',
  hippopotamus: 'imvubu',
  horse: 'ihhashi',
  'humpback-whale': 'i-humpback-whale',
  hyena: 'impisi',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'ingwe',
  lion: 'ibhubesi',
  lizard: 'isibankwa',
  moose: 'i-moose',
  otter: 'otter',
  owl: 'isikhova',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'ingulube',
  rabbit: 'unogwaja',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'ubhejane',
  robin: 'robin',
  rooster: 'iqhude',
  scorpion: 'ufezela',
  shark: 'ushaka',
  sheep: 'izimvu',
  swan: 'swan',
  tiger: 'tiger',
  turkey: 'turkey',
  wolf: 'impisi',
  yak: 'yak',
  zebra: 'idube',
};
