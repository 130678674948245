<template>
  <button
    class="btn hover-transition"
    :class="classObject"
    :disabled="disabled">
    <slot />
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classObject = computed(() => Object.values(props));
    return { classObject };
  },
};
</script>

<style scoped>
.btn {
  @apply rounded-lg font-bold text-center px-4 py-3 min-w-100 sm:min-w-150 md:min-w-200 font-mono;
}

.btn:focus {
  @apply outline-none;
}

/* colors */
.blue {
  @apply bg-blue-500 hover:bg-blue-600 text-white;
}

.green {
  @apply bg-green-500 hover:bg-green-600 text-white;
}

.red {
  @apply bg-red-500 hover:bg-red-600 text-white;
}

.orange {
  @apply bg-yellow-500 hover:bg-yellow-600 text-white;
}

.purple {
  @apply bg-purple-500 hover:bg-purple-600 text-white;
}

.white {
  @apply bg-white hover:bg-gray-200 text-black;
}

.transparent {
  @apply bg-transparent hover:bg-gray-200 text-black;
}

/* sizes */
.small {
  @apply text-xs p-2;
}

.small-medium {
  @apply p-2 text-sm;
}

.normal {
  @apply p-4;
}

.large {
  @apply p-6;
}
</style>
