<template>
  <div class="text-white pt-4 pb-6 flex justify-center items-center">
    <span class="pr-4"><VolumeUp /></span>
    <span><slot>{{ $t('components.gameHintVolumeUp.soundRequired') }}</slot></span>
    <span class="pl-4"><VolumeUp /></span>
  </div>
</template>

<script>
import VolumeUp from '@/components/shared/icons/VolumeUp';

export default {
  name: 'GameHintVolumeUp',
  components: {
    VolumeUp,
  },
};
</script>
