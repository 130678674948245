module.exports = {
  app: {
    title: 'Animal Games',
    description: `Free to play educational mini-games for children with more than 50 different animal sounds.
        Let your kid train his/ her memory by putting the animals in an increasingly more complex order (including blind mode),
        guess animals by their sounds in a fun quiz or just listen to their voices and enjoy them. Play now!`,
    save: 'Save',
    cancel: 'Cancel',
    start: 'Start',
    reset: 'Reset',
    restart: 'Restart',
    name: 'Name',
    submit: 'Submit',
    rounds: 'Rounds',
    trials: 'Trials',
    invalid: {
      name_too_short: 'Error: Name must have at least 1 character',
    },
  },
  gameList: {
    animals_in_order: {
      title: 'Animals In Order',
      text: `
      4 animals are highlighted randomly. Memorize the order and repeat it as it's shown.
      Each round the order increases making it more and more difficult to memorize it.
      You can choose from 50 animals which to play with and how many rounds the game should last.
      `,
    },
    animals_in_order_blind: {
      title: 'Animals In Order - Blind Mode',
      text: `
      The same idea as 'Animals In Order' just with a small twist - You are blind.
      You can only hear the animals but not see them while the order is played. You have to memorize them solely based on their sounds.
          `,
    },
    animal_quiz: {
      title: 'Animal Quiz',
      text: 'Listen to an animal sound and guess to which animal it belongs.',
    },
    animal_sounds: {
      title: 'Animal Sounds',
      text: `
      This is not a game but simply gives you the opportunity to play all animal sounds at your own will.
      `,
    },
  },
  components: {
    animalsInOrder: {
      title: 'Please choose {amount} {more} {animal} to play with',
      more: 'more',
      animal: 'animal | animals',
    },
    gameCard: {
      playNow: 'Play Now',
      comingSoon: 'Coming soon',
    },
    gamePlay: {
      title: {
        blindMode: 'Listen',
        normalMode: 'Watch, Listen',
        text: '{mode} and repeat',
      },
      subTitle: {
        blindMode: 'Listen to the sounds and repeat',
        normalMode: 'Repeat',
        text: '{mode} the animals in the correct order. Each round a the order is increased by one.',
      },
      hint: {
        blindMode: 'required',
        normalMode: 'optional',
        text: 'Sound is {mode}',
      },
    },
    gameBack: {
      back: 'Back to game selection',
    },
    gameConfiguration: {
      title: 'Configure how you want to play the game',
      setRounds: 'Set rounds',
      validate: 'Rounds need to be higher than 0',
    },
    gameEnd: {
      title: {
        won: 'Game Won',
        lost: 'Game Over',
      },
      success: 'Congratulations. You solved {amountRounds} {rounds} with {amountTrials} {trials} left',
      failure: `Oh-Oh. 
      Unfortunately this was incorrect and there are no more trials left. 
      But at least you made it to round {counter}.`,
      highscore: 'Add me to Highscore',
      rounds: 'round | rounds',
      trials: 'trial | trials',
      error: 'An error occurred. Please try to submit again.',
    },
    gameHintVolumeUp: {
      soundRequired: 'Sound is required',
    },
    highscoreList: {
      title: 'Highscore',
      back2Home: 'Back to home',
    },
    stopWatch: {
      time: 'Time',
    },
    languageSelector: {
      title: 'Language selection',
      app: {
        select: 'App Language',
        flag: 'App',
      },
      animals: {
        select: 'Animal Language',
        flag: 'Animals',
      },
    },
  },
  pages: {
    animalQuiz: {
      game: {
        title: 'Which animal makes this sound?',
        subTitle: `
        Each round a different sound is played and a choice of 4 animals is given.
        Guess to which animal the played sound belongs.`,
      },
    },
    animalSounds: {
      start: {
        subTitle: 'Listen to over 50 different animal sounds',
        switch: 'Switch to {view} view',
        grid: 'grid',
        carousel: 'carousel',
      },
    },
  },
  meta: {
    app: {
      title: 'Free-2-Play Animal Sound Games For Kids',
      description: 'Free to play educational mini-games for children with more than 50 different animal sounds. Let your kid train his/ her memory by putting the animals in an increasingly more complex order (including blind mode), guess animals by their sounds in a fun quiz or just listen to their voices and enjoy them. Play now!',
      social: {
        title: 'Animal Games',
        description: 'Play now! - Free educational mini-games for kids with more than 50 different animal sounds.',
      },
    },
    animalsInOrder: {
      start: {
        title: 'Animals In Order - Free-2-Play Animal Sound Game For Kids',
        description: 'Memorize a random order of animals that increase every round by one. Free to play educational mini-game with more than 50 different animal sounds. Play now!',
      },
      highscore: {
        title: 'Animals In Order - Highscore',
        description: 'Highscore of the fastest players of Animals In Order',
      },
    },
    animalsInOrderBlind: {
      start: {
        title: 'Animals In Order - Blind Mode - Free-2-Play Animal Sound Game For Kids',
        description: 'Memorize a random order of animals only by their sound that increase every round by one. Free to play educational mini-game with more than 50 different animal sounds. Play now!',
      },
      highscore: {
        title: 'Animals In Order - Blind Mode - Highscore',
        description: 'Highscore of the fastest players of Animals In Order - Blind Mode',
      },
    },
    animalQuiz: {
      start: {
        title: 'Animal Quiz - Free-2-Play Animal Sound Game For Kids',
        description: 'Listen to more than 50 different animal sounds and guess to which animal each sound belongs. Free to play educational mini-game. Play now!',
      },
      highscore: {
        title: 'Animal Quiz - Highscore',
        description: 'Highscore of the fastest players of Animal Quiz.',
      },
    },
    animalSounds: {
      start: {
        title: 'Animal Sounds',
        description: 'Listen to over 50 different animal sounds for free. A fun and playful way to teach your child the names and sounds of a variety of animals. Try it out now!',
      },
    },
  },
  404: {
    title: 'Page not found',
  },
};
