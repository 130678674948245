import { doc, getDoc } from 'firebase/firestore/lite';
import { firestore } from './init';

/**
 * @param {Array}
 * @returns {Object}
*/
export const getDocRef = (path) => doc(firestore, ...path);

/**
 * @param {Array}
 * @returns {Object}
*/
export const getDataFrom = async(path) => {
  const docSnap = await getDoc(getDocRef(path));

  if (!docSnap.exists()) {
    throw new Error('No such document!');
  }

  return docSnap.data();
};
