module.exports = {
  'african-grey-parrot': 'afrikaanse-grijze-papegaai',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'miereneter',
  antelope: 'antilope',
  ape: 'aap',
  bat: 'vleermuis',
  bee: 'bij',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buffel',
  butterfly: 'vlinder',
  camel: 'kameel',
  cat: 'kat',
  chicken: 'kip',
  cow: 'koe',
  crow: 'kraai',
  dinosaur: 'dinosaurus',
  dog: 'hond',
  dove: 'duif',
  duck: 'eend',
  elephant: 'olifant',
  falcon: 'valk',
  ferret: 'fret',
  frog: 'kikker',
  giraffe: 'giraf',
  'guinea-pig': 'cavia',
  hedgehog: 'egel',
  hippopotamus: 'nijlpaard',
  horse: 'paard',
  'humpback-whale': 'bultrug',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-draak',
  leopard: 'luipaard',
  lion: 'leeuw',
  lizard: 'hagedis',
  moose: 'eland',
  otter: 'otter',
  owl: 'uil',
  panda: 'panda',
  penguin: 'pinguïn',
  pig: 'varken',
  rabbit: 'konijn',
  raccoon: 'wasbeer',
  rat: 'rat',
  rattlesnake: 'ratelslang',
  rhinoceros: 'neushoorn',
  robin: 'robin',
  rooster: 'haan',
  scorpion: 'schorpioen',
  shark: 'haai',
  sheep: 'schapen',
  swan: 'zwaan',
  tiger: 'tijger',
  turkey: 'kalkoen',
  wolf: 'wolf',
  yak: 'jak',
  zebra: 'zebra',
};
