<template>
  <h1 class="text-white text-center pt-8">
    {{ $t('gameList.animal_quiz.title') }}
  </h1>
  <GameConfiguration
    :show="showModal"
    :game-route="pathTo.animal_quiz__game"
    input-type="select"
    @cancel="$router.push(pathTo.home)" />
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import GameConfiguration from '@/components/shared/game_configuration';
import { pathTo } from '@/plugins/router/helper';

export default {
  name: 'AnimalQuizStart',
  components: {
    GameConfiguration,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t('meta.animalQuiz.start.title'),
      meta: [
        { name: 'description', content: t('meta.animalQuiz.start.description') },
      ],
    });

    const showModal = ref(true);

    return {
      showModal,
      pathTo,
    };
  },
};
</script>
