module.exports = {
  'african-grey-parrot': 'african-grey-parrot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'ramanavy',
  bee: 'tantely',
  'bowhead-whale': 'trozona',
  buffalo: 'buffalo',
  butterfly: 'lolo',
  camel: 'rameva',
  cat: 'saka',
  chicken: 'akoho',
  cow: 'omby',
  crow: 'goaika',
  dinosaur: 'dinôzôro',
  dog: 'alika',
  dove: 'voromailala',
  duck: 'gana',
  elephant: 'elefanta',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'sahona',
  giraffe: 'giraffe',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'hedgehog',
  hippopotamus: 'hippopotamus',
  horse: 'soavaly',
  'humpback-whale': 'trozona humpback',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragona',
  leopard: 'leoparda',
  lion: 'liona',
  lizard: 'androngo',
  moose: 'moose',
  otter: 'otter',
  owl: 'vorondolo',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'kisoa',
  rabbit: 'bitro',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'rhinoceros',
  robin: 'robin',
  rooster: 'akoho',
  scorpion: 'maingoka',
  shark: 'antsantsa',
  sheep: 'ondry',
  swan: 'swan',
  tiger: 'tiger',
  turkey: 'turkey',
  wolf: 'amboadia',
  yak: 'yak',
  zebra: 'zebra',
};
