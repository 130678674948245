export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export default (animalNames, amount, unique = false) => {
  const list = [];

  for (let i = 0; i < amount;) {
    const randomAnimal = animalNames[getRandomInt(0, animalNames.length - 1)];

    // decides whether returned list of animals is unique or not
    if (unique) {
      if (!list.includes(randomAnimal)) {
        list.push(randomAnimal);
        i += 1;
      }
    } else if (list[i - 1] !== randomAnimal) {
      list.push(randomAnimal);
      i += 1;
    }
  }

  return list;
};
