export default {
  namespaced: true,
  state: () => ({
    animalSelection: [],
  }),

  getters: {
    animalSelection (state) {
      return state.animalSelection;
    },
    animalSelectionComplete (state) {
      return state.animalSelection.length >= 4;
    },
  },

  actions: {
    setAnimalSelection ({ dispatch }, animal) {
      dispatch('setANIMAL_SELECTION', animal);
    },
    resetAnimalSelection({ dispatch }) {
      const resetState = [];
      dispatch('resetANIMAL_SELECTION', resetState);
    },

    setANIMAL_SELECTION({ commit }, animal) {
      commit('setANIMAL_SELECTION', animal);
    },
    resetANIMAL_SELECTION({ commit }, resetState) {
      commit('resetANIMAL_SELECTION', resetState);
    },
  },

  mutations: {
    setANIMAL_SELECTION(state, animal) {
      if (state.animalSelection.includes(animal)) {
        const index = state.animalSelection.indexOf(animal);
        state.animalSelection.splice(index, 1);
      } else {
        state.animalSelection.push(animal);
      }
    },
    resetANIMAL_SELECTION(state, resetState) {
      state.animalSelection = resetState;
    },
  },
};

