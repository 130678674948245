module.exports = {
  'african-grey-parrot': 'ਅਫਰੀਕਨ-ਗ੍ਰੇ-ਤੋਤਾ',
  alligator: 'ਐਲੀਗੇਟਰ',
  alpaca: 'ਅਲਪਕਾ',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'ਬੈਟ',
  bee: 'ਮਧੂ ਮੱਖੀ',
  'bowhead-whale': 'ਬੌਹਹੈਡ-ਵ੍ਹੇਲ',
  buffalo: 'ਮੱਝ',
  butterfly: 'ਤਿਤਲੀ',
  camel: 'lਠ',
  cat: 'ਬਿੱਲੀ',
  chicken: 'ਚਿਕਨ',
  cow: 'ਗ cow',
  crow: 'ਕਾਂ',
  dinosaur: 'ਡਾਇਨਾਸੌਰ',
  dog: 'ਕੁੱਤਾ',
  dove: 'ਘੁੱਗੀ',
  duck: 'ਬੱਤਖ',
  elephant: 'ਹਾਥੀ',
  falcon: 'ਫਾਲਕਨ',
  ferret: 'ਫੇਰੇਟ',
  frog: 'ਡੱਡੂ',
  giraffe: 'ਜਿਰਾਫ',
  'guinea-pig': 'ਗਿਨੀ-ਪਿਗ',
  hedgehog: 'ਹੇਜਹੌਗ',
  hippopotamus: 'ਹਿੱਪੋਪੋਟੈਮਸ',
  horse: 'ਘੋੜਾ',
  'humpback-whale': 'ਹੰਪਬੈਕ-ਵ੍ਹੇਲ',
  hyena: 'ਹਾਈਨਾ',
  'komodo-dragon': 'ਕੋਮੋਡੋ-ਡ੍ਰੈਗਨ',
  leopard: 'ਚੀਤਾ',
  lion: 'ਸ਼ੇਰ',
  lizard: 'ਕਿਰਲੀ',
  moose: 'ਮੂਸ',
  otter: 'terਟਰ',
  owl: 'ਉੱਲੂ',
  panda: 'ਪਾਂਡਾ',
  penguin: 'ਪੇਂਗੁਇਨ',
  pig: 'ਸੂਰ',
  rabbit: 'ਖਰਗੋਸ਼',
  raccoon: 'ਰੈਕੂਨ',
  rat: 'ਚੂਹਾ',
  rattlesnake: 'ਰੈਟਲਸਨੇਕ',
  rhinoceros: 'ਗੈਂਡੇ',
  robin: 'ਰੌਬਿਨ',
  rooster: 'ਕੁੱਕੜ',
  scorpion: 'ਬਿੱਛੂ',
  shark: 'ਸ਼ਾਰਕ',
  sheep: 'ਭੇਡ',
  swan: 'ਹੰਸ',
  tiger: 'ਟਾਈਗਰ',
  turkey: 'ਟਰਕੀ',
  wolf: 'ਬਘਿਆੜ',
  yak: 'ਯਾਕ',
  zebra: 'ਜ਼ੈਬਰਾ',
};
