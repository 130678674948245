<template>
  <GameHeadline :invisible="isSoundPlaying">
    <template #title>
      <span v-if="!isSoundPlaying">
        {{ $t('gameList.animal_quiz.title') }}
      </span>
      <span v-else>
        {{ $t('pages.animalQuiz.game.title') }}
      </span>
    </template>

    <template #subtitle v-if="!started">
      {{ $t('pages.animalQuiz.game.subTitle') }}
    </template>

    <template #hint v-else>
      <GameHintVolumeUp />
    </template>
  </GameHeadline>

  <GameInfo
    :rounds="rounds"
    :trials="trials"
    :start-timer="startTimer"
    :class="{invisible: isSoundPlaying}"
    v-model:time.number="time" />
  <GameControls
    :highlight-counter="highlightCounter"
    :counter="counter"
    @start="startGame"
    @reset="resetGame">
    <template #start-btn>
      <Button
        @click="!started ? startGame() : isReplay ? playAnimalSound(currentAnimal.name) : nextRound()"
        color="green"
        :disabled="isSoundPlaying"
        data-test-id="game_controls__start">
        {{ !started ? 'Start' : 'Replay' }}
      </Button>
    </template>
  </GameControls>

  <audio
    :src="currentAnimal.audio"
    preload
    :ref="el => { if (el) audio[currentAnimal.name] = el }"
    :data-test-helper="currentAnimal.name" />

  <div class="grid grid-cols-2 gap-4 pt-12" v-if="!isSoundPlaying">
    <Animal
      v-for="animal in animalQuiz" :key="animal.name"
      :animal="animal"
      :selectable="false"
      :highlight="highlightAnimal"
      :show-error="showError"
      :disabled="disabled"
      @update:playerMove="playerMove"
      show-name />
  </div>

  <GameEnd
    :player-won="playerWon"
    :player-lost="playerLost"
    :rounds="rounds"
    :trials="trials"
    :counter="counter"
    :time="time"
    :highscore-route="pathTo.animal_quiz__highscore"
    :document-path="HIGHSCORE__ANIMAL_QUIZ"
    @reset="resetGame" />
</template>

<script>
import {
  ref, computed, watch, inject, nextTick, onBeforeUpdate,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import GameHeadline from '@/components/shared/game_headline';
import GameHintVolumeUp from '@/components/shared/game_hint/GameHintVolumeUp';
import GameInfo from '@/components/shared/game_info';
import GameControls from '@/components/shared/game_controls';
import GameEnd from '@/components/shared/game_end';
import Animal from '@/components/shared/animal';
import Button from '@/components/shared/basic/Button';
import animalList from '@/config/animalList';
import { pathTo } from '@/plugins/router/helper';
import getRandomAnimalNames from '@/helper/game/getRandomAnimalNames';
import { HIGHSCORE__ANIMAL_QUIZ } from '@/config/firestore';

export default {
  name: 'AnimalQuizGame',
  components: {
    GameHeadline,
    GameHintVolumeUp,
    GameInfo,
    GameControls,
    GameEnd,
    Animal,
    Button,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t('meta.animalQuiz.start.title'),
      meta: [
        { name: 'description', content: t('meta.animalQuiz.start.description') },
      ],
    });

    // data
    const { shuffle, find, difference } = inject('utils');
    const store = useStore();
    const router = useRouter();

    const audio = ref([]);
    const started = ref(false);
    const isSoundPlaying = ref(false);
    const disabled = ref(true);
    const isReplay = ref(false);
    const startTimer = ref(false);
    const highlightCounter = ref(false);
    const counter = ref(0);
    const trials = ref(3);
    const time = ref(0);
    const highlightAnimal = ref('');
    const showError = ref('');
    const currentAnimal = ref({
      name: '',
      audio: '',
    });
    const animalQuiz = ref([]);
    const guessedAnimals = ref([]);
    const replayTime = ref(3000);
    const breakBetweenRounds = ref(1000);

    // computed
    const rounds = computed(() => store.getters['current/rounds']);
    const playerWon = computed(() => counter.value >= rounds.value);
    const playerLost = computed(() => trials.value === 0);
    const animalNames = computed(() => animalList.map((animal) => animal.name));

    // methods
    const playAnimalSound = (animal) => {
      isSoundPlaying.value = true;

      currentAnimal.value.name = animal;
      currentAnimal.value.audio = find(animalList, { name: animal }).data.audio;

      nextTick(() => {
        audio.value[animal].play();
      });

      setTimeout(() => {
        isSoundPlaying.value = false;
        startTimer.value = true;
        disabled.value = false;
      }, 3000);
    };

    const getRandomUnusedAnimal = () => {
      const unusedAnimals = difference(animalNames.value, guessedAnimals.value);
      const randomUnusedAnimal = shuffle(unusedAnimals)[0];

      return randomUnusedAnimal;
    };

    const getAnimalData = (animals) => {
      const animalData = animalList
        .filter((animal) => animals.includes(animal.name))
        .map((item) => {
          const animal = item;
          animal.data.color = 'green';

          return animal;
        });

      return animalData;
    };

    const nextRound = () => {
      if (!started.value) return;

      isReplay.value = true;

      const nextAnimal = getRandomUnusedAnimal();
      const animalsWithoutNextAnimal = animalNames.value.filter((animal) => animal !== nextAnimal);
      const randomAnimals = getRandomAnimalNames(animalsWithoutNextAnimal, 3, true);
      const randomUnusedAnimals = shuffle(randomAnimals.concat(nextAnimal));

      animalQuiz.value = getAnimalData(randomUnusedAnimals);
      playAnimalSound(nextAnimal);
    };

    const playerMove = (animal) => {
      const isCorrectMove = currentAnimal.value.name === animal;

      if (isCorrectMove) {
        disabled.value = true;
        startTimer.value = false;
        isReplay.value = false;
        counter.value += 1;
        highlightAnimal.value = animal;
        highlightCounter.value = true;
        guessedAnimals.value.push(animal);

        setTimeout(() => {
          highlightCounter.value = false;
          highlightAnimal.value = '';

          setTimeout(() => {
            nextRound();
          }, breakBetweenRounds.value);
        }, replayTime.value);
      } else {
        startTimer.value = false;
        disabled.value = true;
        showError.value = animal;
        trials.value -= 1;

        setTimeout(() => {
          showError.value = '';

          playAnimalSound(currentAnimal.value.name);
        }, replayTime.value);
      }
    };

    const startGame = () => {
      started.value = true;
      nextRound();
    };

    const stopGame = () => {
      started.value = false;
      disabled.value = true;
      startTimer.value = false;
    };

    const resetGame = () => {
      router.push(pathTo.animal_quiz__start);
    };

    // watchers
    watch(trials, () => {
      if (playerLost.value) {
        stopGame();
      }
    });

    watch(counter, () => {
      if (playerWon.value) {
        stopGame();
      }
    });

    // lifecycle
    onBeforeUpdate(() => {
      audio.value = [];
    });

    return {
      audio,
      started,
      isSoundPlaying,
      disabled,
      isReplay,
      startTimer,
      highlightCounter,
      counter,
      trials,
      time,
      highlightAnimal,
      showError,
      currentAnimal,
      animalQuiz,
      rounds,
      startGame,
      resetGame,
      playerWon,
      playerLost,
      playerMove,
      playAnimalSound,
      pathTo,
      HIGHSCORE__ANIMAL_QUIZ,
    };
  },
};
</script>
