module.exports = {
  'african-grey-parrot': 'afrikano-gri-papagall',
  alligator: 'aligator',
  alpaca: 'alpaca',
  anteater: 'milingonë',
  antelope: 'antilopë',
  ape: 'majmun',
  bat: 'shkop',
  bee: 'bletë',
  'bowhead-whale': 'balenë me hark',
  buffalo: 'bualli',
  butterfly: 'flutur',
  camel: 'deve',
  cat: 'mace',
  chicken: 'pulë',
  cow: 'lopë',
  crow: 'sorrë',
  dinosaur: 'dinosaur',
  dog: 'qen',
  dove: 'pëllumb',
  duck: 'rosë',
  elephant: 'elefant',
  falcon: 'skifter',
  ferret: 'ferret',
  frog: 'bretkocë',
  giraffe: 'gjirafë',
  'guinea-pig': 'derr gini',
  hedgehog: 'iriq',
  hippopotamus: 'hipopotami',
  horse: 'kali',
  'humpback-whale': 'balena me gunga',
  hyena: 'hiena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'leopardi',
  lion: 'luan',
  lizard: 'hardhucë',
  moose: 'moose',
  otter: 'vidër',
  owl: 'buf',
  panda: 'panda',
  penguin: 'pinguin',
  pig: 'derr',
  rabbit: 'lepur',
  raccoon: 'rakun',
  rat: 'miu',
  rattlesnake: 'gjarpër zhare',
  rhinoceros: 'rinoceronti',
  robin: 'robin',
  rooster: 'gjeli',
  scorpion: 'akrep',
  shark: 'peshkaqen',
  sheep: 'dele',
  swan: 'mjellmë',
  tiger: 'tigër',
  turkey: 'gjeldeti',
  wolf: 'ujku',
  yak: 'jak',
  zebra: 'zebra',
};
