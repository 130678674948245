<template>
  <GameHeadline :invisible="isReplay">
    <template #title>
      <span v-if="!isReplay">{{ title }}</span>
      <span v-else>
        {{ $t('components.gamePlay.title.text', {
          mode: isBlindMode
            ? $t('components.gamePlay.title.blindMode')
            : $t('components.gamePlay.title.normalMode')
        }) }}
      </span>
    </template>

    <template #subtitle v-if="!started">
      {{ $t('components.gamePlay.subTitle.text', {
        mode: isBlindMode
          ? $t('components.gamePlay.subTitle.blindMode')
          : $t('components.gamePlay.subTitle.normalMode')
      }) }}
    </template>

    <template #hint v-else>
      <GameHintVolumeUp>
        {{ $t('components.gamePlay.hint.text', {
          mode: isBlindMode
            ? $t('components.gamePlay.hint.blindMode')
            : $t('components.gamePlay.hint.normalMode')
        }) }}
      </GameHintVolumeUp>
    </template>
  </GameHeadline>

  <GameInfo
    :rounds="rounds"
    :trials="trials"
    :start-timer="startTimer"
    :class="{invisible: isReplay}"
    v-model:time.number="time" />
  <GameControls
    :highlight-counter="highlightCounter"
    :counter="counter"
    :disable-start-btn="started"
    @start="startGame"
    @reset="resetGame" />
  <div class="grid grid-cols-2 gap-4 pt-10 sm:pt-15">
    <Animal
      v-for="animal in animals" :key="animal.name"
      :animal="animal"
      :selectable="false"
      :highlight="highlightAnimal"
      :show-error="showError"
      :disabled="disabled"
      :is-blind-mode="isBlindMode && isReplay"
      @update:playerMove="updatePlayerMove" />
  </div>
  <GameEnd
    :player-won="playerWon"
    :player-lost="playerLost"
    :rounds="rounds"
    :trials="trials"
    :counter="counter"
    :time="time"
    :highscore-route="highscoreRoute"
    :document-path="documentPath"
    @reset="resetGame" />
</template>

<script>
import {
  ref, toRefs, computed, watch, inject,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Animal from '@/components/shared/animal';
import GameHeadline from '@/components/shared/game_headline';
import GameHintVolumeUp from '@/components/shared/game_hint/GameHintVolumeUp';
import GameInfo from '@/components/shared/game_info';
import GameControls from '@/components/shared/game_controls';
import GameEnd from '@/components/shared/game_end';
import animalList from '@/config/animalList';
import getRandomAnimalNames from '@/helper/game/getRandomAnimalNames';

export default {
  name: 'GamePlay',
  components: {
    Animal,
    GameHeadline,
    GameHintVolumeUp,
    GameInfo,
    GameControls,
    GameEnd,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    resetGameRoute: {
      type: String,
      required: true,
      default: '/',
    },
    highscoreRoute: {
      type: String,
      required: true,
      default: '/',
    },
    documentPath: {
      type: Array,
      required: true,
    },
    isBlindMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isEqual } = inject('utils');
    const store = useStore();
    const router = useRouter();

    // data
    const started = ref(false);
    const isReplay = ref(false);
    const disabled = ref(true);
    const startTimer = ref(false);
    const highlightCounter = ref(false);
    const counter = ref(0);
    const trials = ref(3);
    const move = ref(0);
    const time = ref(0);
    const randomAnimalNames = ref([]);
    const playerMoves = ref([]);
    const highlightColors = ref(['gold', 'green', 'purple', 'blue']);
    const highlightAnimal = ref('');
    const showError = ref('');
    const breakBetweenRounds = ref(1000);

    // props
    const { isBlindMode, resetGameRoute } = toRefs(props);

    // store getters
    const animalSelection = computed(() => store.getters['animalsInOrder/animalSelection']);
    const rounds = computed(() => store.getters['current/rounds']);

    // computed
    const animals = computed(() => {
      const animalData = animalList
        .filter((animal) => animalSelection.value.includes(animal.name))
        .map((item, index) => {
          const animal = item;
          animal.data.color = highlightColors.value[index];

          return animal;
        });

      return animalData;
    });
    const animalNames = computed(() => animals.value.map((animal) => animal.name));
    const computerMoves = computed(() => randomAnimalNames.value.slice(0, counter.value + 1));
    const computerMoveTime = computed(() => (isBlindMode.value ? 3000 : 2000));
    const isPlayerReplayingMoves = computed(() => !isReplay.value && computerMoves.value.length >= playerMoves.value.length);
    const isCorrectMove = computed(() => computerMoves.value[move.value] === playerMoves.value[move.value]);
    const isCorrectMoveOrder = computed(() => isEqual(computerMoves.value, playerMoves.value));
    const playerWon = computed(() => counter.value >= rounds.value);
    const playerLost = computed(() => trials.value === 0);

    // methods
    const updatePlayerMove = (animal) => {
      playerMoves.value = playerMoves.value.concat(animal);
    };
    const replayComputerMoves = () => {
      if (!started.value) return;

      isReplay.value = true;
      playerMoves.value = [];
      move.value = 0;

      computerMoves.value.forEach((animal, i) => {
        setTimeout(() => {
          highlightAnimal.value = animal;

          if (i === computerMoves.value.length - 1) {
            setTimeout(() => {
              highlightAnimal.value = '';
              isReplay.value = false;
              disabled.value = false;
              startTimer.value = true;
            }, computerMoveTime.value);
          }
        }, i * computerMoveTime.value);
      });
    };
    const startGame = () => {
      started.value = true;

      replayComputerMoves();
    };
    const stopGame = () => {
      started.value = false;
      disabled.value = true;
      startTimer.value = false;
    };
    const resetGame = () => {
      router.push(resetGameRoute.value);
    };

    // watchers
    watch(isReplay, () => {
      document.querySelectorAll('body, html').forEach((item) => {
        item.classList.toggle('bg-black');
      });
    });

    watch(playerMoves, () => {
      if (isPlayerReplayingMoves.value) {
        if (isCorrectMoveOrder.value) {
          disabled.value = true;
          startTimer.value = false;
          counter.value += 1;

          highlightAnimal.value = playerMoves.value[move.value];
          highlightCounter.value = true;

          setTimeout(() => {
            highlightCounter.value = false;
            highlightAnimal.value = '';

            setTimeout(() => {
              replayComputerMoves();
            }, breakBetweenRounds.value);
          }, computerMoveTime.value);
        } else if (isCorrectMove.value) {
          highlightAnimal.value = playerMoves.value[move.value];
          move.value += 1;
        } else {
          startTimer.value = false;
          disabled.value = true;
          highlightAnimal.value = computerMoves.value[move.value];
          showError.value = playerMoves.value[move.value];
          trials.value -= 1;

          setTimeout(() => {
            showError.value = '';
            highlightAnimal.value = '';

            setTimeout(() => {
              replayComputerMoves();
            }, breakBetweenRounds.value);
          }, computerMoveTime.value);
        }
      }
    });

    watch(trials, () => {
      if (playerLost.value) {
        stopGame();
      }
    });

    watch(counter, () => {
      if (playerWon.value) {
        stopGame();
      }
    });

    randomAnimalNames.value = getRandomAnimalNames(animalNames.value, rounds.value, false);

    return {
      started,
      isReplay,
      disabled,
      startTimer,
      highlightCounter,
      counter,
      trials,
      time,
      highlightAnimal,
      showError,
      rounds,
      startGame,
      resetGame,
      animals,
      playerLost,
      playerWon,
      updatePlayerMove,
    };
  },
};
</script>
