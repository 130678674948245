module.exports = {
  'african-grey-parrot': 'pappagallo grigio africano',
  alligator: 'alligatore',
  alpaca: 'alpaca',
  anteater: 'formichiere',
  antelope: 'antilope',
  ape: 'scimmia',
  bat: 'pipistrello',
  bee: 'ape',
  'bowhead-whale': 'balenottera',
  buffalo: 'bufalo',
  butterfly: 'farfalla',
  camel: 'cammello',
  cat: 'gatto',
  chicken: 'pollo',
  cow: 'mucca',
  crow: 'corvo',
  dinosaur: 'dinosauro',
  dog: 'cane',
  dove: 'colomba',
  duck: 'anatra',
  elephant: 'elefante',
  falcon: 'falco',
  ferret: 'furetto',
  frog: 'rana',
  giraffe: 'giraffa',
  'guinea-pig': "porcellino d'India",
  hedgehog: 'riccio',
  hippopotamus: 'ippopotamo',
  horse: 'cavallo',
  'humpback-whale': 'gobba',
  hyena: 'iena',
  'komodo-dragon': 'drago di Komodo',
  leopard: 'leopardo',
  lion: 'leone',
  lizard: 'lucertola ',
  moose: 'alce',
  otter: 'lontra',
  owl: 'gufo',
  panda: 'panda',
  penguin: 'pinguino',
  pig: 'maiale',
  rabbit: 'coniglio',
  raccoon: 'procione',
  rat: 'topo',
  rattlesnake: 'serpente a sonagli',
  rhinoceros: 'rinoceronte',
  robin: 'pettirosso',
  rooster: 'gallo',
  scorpion: 'scorpione',
  shark: 'squalo',
  sheep: 'pecora',
  swan: 'cigno',
  tiger: 'tigre',
  turkey: 'tacchino',
  wolf: 'lupo',
  yak: 'yak',
  zebra: 'zebra',
};
