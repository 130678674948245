import animalTranslations from '@/config/animal_translations';

export default {
  namespaced: true,
  state: () => ({
    translations: animalTranslations,
  }),

  getters: {
    translations (state) {
      return state.translations;
    },
  },
};

