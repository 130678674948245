import store from '@/plugins/store';
import router from '@/plugins/router';
import utils from '@/plugins/utils';
import firebase from '@/plugins/firebase';
import vueMeta from '@/plugins/vue_meta';
import i18n from '@/plugins/i18n';
import i18nIso from '@/plugins/i18nIso';

export default {
  store,
  router,
  utils,
  firebase,
  vueMeta,
  i18n,
  i18nIso,
};
