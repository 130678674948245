<template>
  <div data-test-id="language_selector__container">
    <Modal
      :show="show"
      :title="$t('components.languageSelector.title')"
      :save-btn="$t('app.save')"
      @save="save"
      @cancel="show = false">
      <div class="flex gap-x-16">
        <Select
          :select="getName(appLanguage, locale)"
          @update:select="appLanguage = getAlpha2Code($event, locale)"
          :items="appLanguages.map((lang) => getName(lang, locale))"
          :label="$t('components.languageSelector.app.select')"
          :selected="getName(appLanguage, locale)"
          data-test-id="language_selector__app_select" />
        <Select
          :select="getName(animalLanguage, locale)"
          @update:select="animalLanguage = getAlpha2Code($event, locale)"
          :items="animalLanguages.map((lang) => getName(lang, locale))"
          :label="$t('components.languageSelector.animals.select')"
          :selected="getName(animalLanguage, locale)"
          data-test-id="language_selector__animal_select" />
      </div>
      <template #activator>
        <div class="flex p-4" @click="onOpenModal" data-test-id="language_selector__activator">
          <Flag
            class="pr-6"
            :flag="appFlag"
            :title="$t('components.languageSelector.app.flag')"
            data-test-helper="flag__app" />
          <Flag
            :flag="findKey(animalTranslations, animalFlag)"
            :title="$t('components.languageSelector.animals.flag')"
            data-test-helper="flag__animal" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  ref, computed, onBeforeMount, inject,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { appLanguages, animalLanguages } from '@/config/languages';
import Modal from '@/components/shared/basic/Modal';
import Select from '@/components/shared/basic/Select';
import Flag from '@/components/shared/icons/Flag';

export default {
  name: 'LanguageSelector',
  components: {
    Modal,
    Select,
    Flag,
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    const { getName, getAlpha2Code } = inject('i18nISO');
    const { findKey } = inject('utils');
    const animalTranslations = computed(() => store.getters['animalTranslations/translations']);
    const selectedAppLanguage = computed(() => store.getters['user/appLanguage']);
    const selectedAnimalLanguage = computed(() => store.getters['user/animalLanguage']);

    const appLanguage = ref(selectedAppLanguage.value || locale.value);
    const animalLanguage = ref(selectedAnimalLanguage.value || locale.value);
    const appFlag = ref(appLanguage.value);
    const animalFlag = ref(animalLanguage.value);
    const show = ref(false);

    const setAppLanguage = () => {
      store.dispatch('user/setAppLanguage', appLanguage.value);
      locale.value = appLanguage.value;
      appFlag.value = appLanguage.value;
    };

    const setAnimalLanguage = () => {
      store.dispatch('user/setAnimalLanguage', animalLanguage.value);
      animalFlag.value = animalLanguage.value;
    };

    const save = () => {
      show.value = false;

      if (selectedAppLanguage.value !== appLanguage.value) {
        setAppLanguage();
      }

      if (selectedAnimalLanguage.value !== animalLanguage.value) {
        setAnimalLanguage();
      }
    };

    const onOpenModal = () => {
      appLanguage.value = selectedAppLanguage.value;
      animalLanguage.value = selectedAnimalLanguage.value;
      show.value = true;
    };

    onBeforeMount(() => {
      if (!selectedAppLanguage.value) {
        setAppLanguage(locale.value);
      } else {
        setAppLanguage(selectedAppLanguage.value);
      }

      if (!selectedAnimalLanguage.value) {
        setAnimalLanguage(locale.value);
      } else {
        setAnimalLanguage(selectedAnimalLanguage.value);
      }
    });


    return {
      show,
      onOpenModal,
      save,
      appFlag,
      animalFlag,
      appLanguage,
      appLanguages,
      animalLanguage,
      animalLanguages,
      getName,
      getAlpha2Code,
      findKey,
      animalTranslations,
      locale,
    };
  },
};
</script>
