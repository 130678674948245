module.exports = {
  'african-grey-parrot': 'papagalul gri-african',
  alligator: 'aligatorul',
  alpaca: 'alpaca',
  anteater: 'furnică',
  antelope: 'antilopă',
  ape: 'maimuță',
  bat: 'liliac',
  bee: 'albină',
  'bowhead-whale': 'balenă',
  buffalo: 'bivol',
  butterfly: 'fluture',
  camel: 'cămilă',
  cat: 'pisică',
  chicken: 'pui',
  cow: 'vacă',
  crow: 'corb',
  dinosaur: 'dinozaur',
  dog: 'câine',
  dove: 'porumbel',
  duck: 'rață',
  elephant: 'elefant',
  falcon: 'șoim',
  ferret: 'dihor',
  frog: 'broască',
  giraffe: 'girafă',
  'guinea-pig': 'cobai',
  hedgehog: 'arici',
  hippopotamus: 'hipopotam',
  horse: 'cal',
  'humpback-whale': 'balenă cu cocoașă',
  hyena: 'hiena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'leopard',
  lion: 'leu',
  lizard: 'șopârlă',
  moose: 'elan',
  otter: 'vidră',
  owl: 'bufniță',
  panda: 'panda',
  penguin: 'pinguin',
  pig: 'porc',
  rabbit: 'iepure',
  raccoon: 'raton',
  rat: 'șobolan',
  rattlesnake: 'șarpe cu clopoței',
  rhinoceros: 'rinocer',
  robin: 'robin',
  rooster: 'cocoș',
  scorpion: 'scorpion',
  shark: 'rechin',
  sheep: 'oaie',
  swan: 'lebădă',
  tiger: 'tigru',
  turkey: 'curcan',
  wolf: 'lup',
  yak: 'iac',
  zebra: 'zebră',
};
