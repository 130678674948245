module.exports = {
  'african-grey-parrot': 'african-grey-parrot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buabhall',
  butterfly: 'dealan-dè',
  camel: 'camel',
  cat: 'cat',
  chicken: 'cearc',
  cow: 'bò',
  crow: 'feannag',
  dinosaur: 'dinosaur',
  dog: 'cù',
  dove: 'calman',
  duck: 'tunnag',
  elephant: 'ailbhean',
  falcon: 'seabhag',
  ferret: 'ferret',
  frog: 'losgann',
  giraffe: 'giraffe',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'gràineag',
  hippopotamus: 'hippopotamus',
  horse: 'each',
  'humpback-whale': 'humpback-whale',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'leopard',
  lion: 'leòmhann',
  lizard: 'dearc',
  moose: 'moose',
  otter: 'dòbhran',
  owl: 'comhachag',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'muc',
  rabbit: 'coineanach',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'sròn-adharcach',
  robin: 'robin',
  rooster: 'ròc',
  scorpion: 'scorpion',
  shark: 'siorc',
  sheep: 'caoraich',
  swan: 'eala',
  tiger: 'tìgear',
  turkey: 'turcaidh',
  wolf: 'madadh-allaidh',
  yak: 'yak',
  zebra: 'zebra',
};
