import { getFileNames, getFullFileNames } from '@/helper/files';

const names = getFileNames(require.context('@/assets/images/animals', true, /\.(.+)$/i));
const images = getFullFileNames(require.context('@/assets/images/animals', true, /\.(png|jpg|jpeg|svg)$/i));
const audio = getFullFileNames(require.context('@/assets/audio/animals', true, /\.(mp3)$/i));

const animalList = [];
names.forEach((name, index) => {
  animalList.push({
    name,
    data: {
      image: require(`@/assets/images/animals/${images[index]}`),
      audio: require(`@/assets/audio/animals/${audio[index]}`),
    },
  });
});

export const animalListLength = animalList.length;

export default animalList;
