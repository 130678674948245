module.exports = {
  'african-grey-parrot': 'אפרו-אפור-תוכי',
  alligator: 'תנין',
  alpaca: 'אלפקה',
  anteater: 'אכלנית נמלים',
  antelope: 'אנטילופה',
  ape: 'קוף',
  bat: 'עטלף',
  bee: 'דבורה',
  'bowhead-whale': 'לוויתן קשתות',
  buffalo: 'באפלו',
  butterfly: 'פרפר',
  camel: 'גמל',
  cat: 'חתול',
  chicken: 'עוף',
  cow: 'פרה',
  crow: 'עורב',
  dinosaur: 'דינוזאור',
  dog: 'כלב',
  dove: 'יונה',
  duck: 'ברווז',
  elephant: 'פיל',
  falcon: 'בז',
  ferret: 'חמוס',
  frog: 'צפרדע',
  giraffe: "ג'ירפה",
  'guinea-pig': 'שפן ניסיונות',
  hedgehog: 'קיפוד',
  hippopotamus: 'היפופוטם',
  horse: 'סוס',
  'humpback-whale': 'לווייתן-גבנון',
  hyena: 'צבוע',
  'komodo-dragon': 'קומודו-דרקון',
  leopard: 'נמר',
  lion: 'אריה',
  lizard: 'לטאה',
  moose: 'אייל',
  otter: 'לוטרה',
  owl: 'ינשוף',
  panda: 'פנדה',
  penguin: 'פינגווין',
  pig: 'חזיר',
  rabbit: 'ארנב',
  raccoon: 'דביבון',
  rat: 'חולדה',
  rattlesnake: 'נחש רעשן',
  rhinoceros: 'קרנף',
  robin: 'רובין',
  rooster: 'תרנגול',
  scorpion: 'עקרב',
  shark: 'כריש',
  sheep: 'כבשה',
  swan: 'ברבור',
  tiger: 'נמר',
  turkey: 'הודו',
  wolf: 'זאב',
  yak: 'יאק',
  zebra: 'זברה',
};
