module.exports = {
  'african-grey-parrot': 'แอฟริกันสีเทานกแก้ว',
  alligator: 'จระเข้',
  alpaca: 'อัลปาก้า',
  anteater: 'ตัวกินมด',
  antelope: 'ละมั่ง',
  ape: 'ลิง',
  bat: 'ค้างคาว',
  bee: 'ผึ้ง',
  'bowhead-whale': 'วาฬหัวโค้ง',
  buffalo: 'ควาย',
  butterfly: 'ผีเสื้อ',
  camel: 'อูฐ',
  cat: 'แมว',
  chicken: 'ไก่',
  cow: 'วัว',
  crow: 'อีกา',
  dinosaur: 'ไดโนเสาร์',
  dog: 'สุนัข',
  dove: 'นกพิราบ',
  duck: 'เป็ด',
  elephant: 'ช้าง',
  falcon: 'เหยี่ยว',
  ferret: 'คุ้ยเขี่ย',
  frog: 'กบ',
  giraffe: 'ยีราฟ',
  'guinea-pig': 'หนูตะเภา',
  hedgehog: 'เม่น',
  hippopotamus: 'ฮิปโปโปเตมัส',
  horse: 'ม้า',
  'humpback-whale': 'วาฬหลังค่อม',
  hyena: 'ไฮยีน่า',
  'komodo-dragon': 'มังกรโคโมโด',
  leopard: 'เสือดาว',
  lion: 'สิงโต',
  lizard: 'จิ้งจก',
  moose: 'มูส',
  otter: 'นาก',
  owl: 'นกฮูก',
  panda: 'หมีแพนด้า',
  penguin: 'เพนกวิน',
  pig: 'หมู',
  rabbit: 'กระต่าย',
  raccoon: 'แรคคูน',
  rat: 'หนู',
  rattlesnake: 'งูหางกระดิ่ง',
  rhinoceros: 'แรด',
  robin: 'โรบิน',
  rooster: 'ไก่',
  scorpion: 'แมงป่อง',
  shark: 'ฉลาม',
  sheep: 'แกะ',
  swan: 'หงส์',
  tiger: 'เสือ',
  turkey: 'ไก่งวง',
  wolf: 'หมาป่า',
  yak: 'จามรี',
  zebra: 'ม้าลาย',
};
