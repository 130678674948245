module.exports = {
  'african-grey-parrot': 'африкансько-сірий папуга',
  alligator: 'алігатор',
  alpaca: 'альпака',
  anteater: 'мурахоїд',
  antelope: 'антилопа',
  ape: 'мавпа',
  bat: 'кажан',
  bee: 'бджола',
  'bowhead-whale': 'кит-цибуля',
  buffalo: 'буйвол',
  butterfly: 'метелик',
  camel: 'верблюд',
  cat: 'кішка',
  chicken: 'курка',
  cow: 'корова',
  crow: 'ворона',
  dinosaur: 'динозавр',
  dog: 'собака',
  dove: 'голуб',
  duck: 'качка',
  elephant: 'слон',
  falcon: 'сокіл',
  ferret: 'тхір',
  frog: 'жаба',
  giraffe: 'жираф',
  'guinea-pig': 'морська свинка',
  hedgehog: 'їжак',
  hippopotamus: 'бегемот',
  horse: 'кінь',
  'humpback-whale': 'горбатий кит',
  hyena: 'гієна',
  'komodo-dragon': 'комодо-дракон',
  leopard: 'леопард',
  lion: 'лев',
  lizard: 'ящірка',
  moose: 'лось',
  otter: 'видра',
  owl: 'сова',
  panda: 'панда',
  penguin: 'пінгвін',
  pig: 'свиня',
  rabbit: 'кролик',
  raccoon: 'єнот',
  rat: 'щур',
  rattlesnake: 'гримуча змія',
  rhinoceros: 'носоріг',
  robin: 'маліночок',
  rooster: 'півень',
  scorpion: 'скорпіон',
  shark: 'акула',
  sheep: 'вівця',
  swan: 'лебідь',
  tiger: 'тигр',
  turkey: 'індичка',
  wolf: 'вовк',
  yak: 'як',
  zebra: 'зебра',
};
