module.exports = {
  'african-grey-parrot': 'африкалық-сұр-попугая',
  alligator: 'аллигатор',
  alpaca: 'альпака',
  anteater: 'құмырсқа',
  antelope: 'бөкен',
  ape: 'маймыл',
  bat: 'жарқанат',
  bee: 'ара',
  'bowhead-whale': 'садақ-кит',
  buffalo: 'буйвол',
  butterfly: 'көбелек',
  camel: 'түйе',
  cat: 'мысық',
  chicken: 'тауық',
  cow: 'сиыр',
  crow: 'қарға',
  dinosaur: 'динозавр',
  dog: 'ит',
  dove: 'көгершін',
  duck: 'үйрек',
  elephant: 'піл',
  falcon: 'сұңқар',
  ferret: 'құс',
  frog: 'бақа',
  giraffe: 'жираф',
  'guinea-pig': 'теңіз шошқасы',
  hedgehog: 'кірпі',
  hippopotamus: 'бегемот',
  horse: 'жылқы',
  'humpback-whale': 'өркеш кит',
  hyena: 'гиена',
  'komodo-dragon': 'комодо-айдаһар',
  leopard: 'барыс',
  lion: 'арыстан',
  lizard: 'кесіртке',
  moose: 'бұлан',
  otter: 'саман',
  owl: 'үкі',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'шошқа',
  rabbit: 'қоян',
  raccoon: 'ракон',
  rat: 'егеуқұйрық',
  rattlesnake: 'шырылдаған жылан',
  rhinoceros: 'керік',
  robin: 'робин',
  rooster: 'әтеш',
  scorpion: 'шаян',
  shark: 'акула',
  sheep: 'қой',
  swan: 'аққу',
  tiger: 'жолбарыс',
  turkey: 'күркетауық',
  wolf: 'қасқыр',
  yak: 'як',
  zebra: 'зебра',
};
