import { createApp } from 'vue';
import App from './App';
import plugins from './plugins';
import './registerServiceWorker';
import './assets/styles/tailwind.css';


const app = createApp(App);

Object.keys(plugins).forEach((plugin) => {
  app.use(plugins[plugin]);
});

app.mount('#app');
