<template>
  <HighscoreList
    :back-route="pathTo.home"
    :document-path="HIGHSCORE__ANIMAL_QUIZ" />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import HighscoreList from '@/components/shared/highscore_list';
import { pathTo } from '@/plugins/router/helper';
import { HIGHSCORE__ANIMAL_QUIZ } from '@/config/firestore';

export default {
  name: 'AnimalQuizHighscore',
  components: {
    HighscoreList,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t('meta.animalQuiz.highscore.title'),
      meta: [
        { name: 'description', content: t('meta.animalQuiz.highscore.description') },
      ],
    });

    return {
      pathTo,
      HIGHSCORE__ANIMAL_QUIZ,
    };
  },
};
</script>
