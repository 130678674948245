module.exports = {
  'african-grey-parrot': 'Afrika-kelabu-burung beo',
  alligator: 'buaya',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'kera',
  bat: 'kelawar',
  bee: 'lebah',
  'bowhead-whale': 'paus bowhead',
  buffalo: 'kerbau',
  butterfly: 'rama-rama',
  camel: 'unta',
  cat: 'kucing',
  chicken: 'ayam',
  cow: 'lembu',
  crow: 'gagak',
  dinosaur: 'dinosaur',
  dog: 'anjing',
  dove: 'merpati',
  duck: 'itik',
  elephant: 'gajah',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'katak',
  giraffe: 'zirafah',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'landak',
  hippopotamus: 'kuda nil',
  horse: 'kuda',
  'humpback-whale': 'bungkuk-paus',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-naga',
  leopard: 'macan tutul',
  lion: 'singa',
  lizard: 'kadal',
  moose: 'rusa',
  otter: 'berang-berang',
  owl: 'burung hantu',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'babi',
  rabbit: 'arnab',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'badak',
  robin: 'robin',
  rooster: 'ayam jantan',
  scorpion: 'kala jengking',
  shark: 'jerung',
  sheep: 'domba',
  swan: 'angsa',
  tiger: 'harimau',
  turkey: 'kalkun',
  wolf: 'serigala',
  yak: 'yak',
  zebra: 'zebra',
};
