module.exports = {
  'african-grey-parrot': 'Afrikanischer Graupapagei',
  alligator: 'Alligator',
  alpaca: 'Alpaka',
  anteater: 'Ameisenbär',
  antelope: 'Antilope',
  ape: 'Affe',
  bat: 'Fledermaus',
  bee: 'Biene',
  'bowhead-whale': 'Grobkopfwal',
  buffalo: 'Büffel',
  butterfly: 'Schmetterling',
  camel: 'Kamel',
  cat: 'Katze',
  chicken: 'Huhn',
  cow: 'Kuh',
  crow: 'Krähe',
  dinosaur: 'Dinosaurier',
  dog: 'Hund',
  dove: 'Taube',
  duck: 'Ente',
  elephant: 'Elefant',
  falcon: 'Falke',
  ferret: 'Frettchen',
  frog: 'Frosch',
  giraffe: 'Giraffe',
  'guinea-pig': 'Meerschweinchen',
  hedgehog: 'Igel',
  hippopotamus: 'Nilpferd',
  horse: 'Pferd',
  'humpback-whale': 'Buckelwal',
  hyena: 'Hyäne',
  'komodo-dragon': 'Komodo-Drache',
  leopard: 'Leopard',
  lion: 'Löwe',
  lizard: 'Eidechse ',
  moose: 'Elch',
  otter: 'Otter',
  owl: 'Eule',
  panda: 'Panda',
  penguin: 'Pinguin',
  pig: 'Schwein',
  rabbit: 'Kaninchen',
  raccoon: 'Waschbär',
  rat: 'Ratte',
  rattlesnake: 'Klapperschlange',
  rhinoceros: 'Nashorn',
  robin: 'Rotkehlchen',
  rooster: 'Hahn',
  scorpion: 'Skorpion',
  shark: 'Hai',
  sheep: 'Schaf',
  swan: 'Schwan',
  tiger: 'Tiger',
  turkey: 'Truthahn',
  wolf: 'Wolf',
  yak: 'Yak',
  zebra: 'Zebra',
};

