<template>
  <section class="p-4 text-center text-white">
    <h1 data-test-id="home__title">{{ $t('app.title') }}</h1>
    <h2 class="pt-4">{{ $t('app.description') }}</h2>
    <div class="pt-12 grid grid-cols-1 xl:grid-cols-2 gap-4">
      <GameCard
        v-for="(game) in gameList" :key="game.id"
        :img-src="game.preview"
        :title="$t(`gameList.${game.id}.title`)"
        :text="$t(`gameList.${game.id}.text`)"
        :playable="game.playable"
        @click="playGame(game.id)"
        :data-test-id="game.id" />
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import GameCard from '@/components/game_card';
import { pathTo } from '@/plugins/router/helper';
import gameList from '@/config/gameList';

export default {
  name: 'Home',
  components: {
    GameCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const setGame = (game) => store.dispatch('user/setGame', game);

    const playGame = (game) => {
      setGame(game);
      router.push(pathTo[`${game}__start`]);
    };

    const removeBlindModeStyling = () => {
      window.document.querySelectorAll('body, html').forEach((elem) => elem.classList.remove('blindMode'));
    };

    onMounted(() => {
      removeBlindModeStyling();
    });

    return {
      gameList,
      playGame,
      setGame,
    };
  },
};
</script>
