export default {
  namespaced: true,
  state: () => ({
    game: '',
    appLanguage: null,
    animalLanguage: null,
  }),

  getters: {
    isGame (state) {
      return state.game;
    },
    appLanguage (state) {
      return state.appLanguage;
    },
    animalLanguage (state) {
      return state.animalLanguage;
    },
  },

  actions: {
    setGame ({ dispatch }, game) {
      dispatch('setGAME', game);
    },
    setAppLanguage ({ dispatch }, language) {
      dispatch('setAPP_LANGUAGE', language);
    },
    setAnimalLanguage ({ dispatch }, language) {
      dispatch('setANIMAL_LANGUAGE', language);
    },
    setGAME({ commit }, game) {
      commit('setGAME', game);
    },
    setAPP_LANGUAGE({ commit }, language) {
      commit('setAPP_LANGUAGE', language);
    },
    setANIMAL_LANGUAGE({ commit }, language) {
      commit('setANIMAL_LANGUAGE', language);
    },
  },
  mutations: {
    setGAME(state, game) {
      state.game = game;
    },
    setAPP_LANGUAGE(state, language) {
      state.appLanguage = language;
    },
    setANIMAL_LANGUAGE(state, language) {
      state.animalLanguage = language;
    },
  },
};

