module.exports = {
  'african-grey-parrot': 'vẹt xám châu Phi',
  alligator: 'cá sấu',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'trâu',
  butterfly: 'bướm',
  camel: 'lạc đà',
  cat: 'mèo',
  chicken: 'gà',
  cow: 'bò',
  crow: 'quạ',
  dinosaur: 'khủng long',
  dog: 'chó',
  dove: 'bồ câu',
  duck: 'vịt',
  elephant: 'voi',
  falcon: 'chim ưng',
  ferret: 'chồn sương',
  frog: 'ếch',
  giraffe: 'hươu cao cổ',
  'guinea-pig': 'chuột lang',
  hedgehog: 'nhím',
  hippopotamus: 'hà mã',
  horse: 'ngựa',
  'humpback-whale': 'cá voi lưng gù',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'leopard',
  lion: 'sư tử',
  lizard: 'thằn lằn',
  moose: 'con nai sừng tấm',
  otter: 'rái cá',
  owl: 'cú',
  panda: 'gấu trúc',
  penguin: 'chim cánh cụt',
  pig: 'lợn',
  rabbit: 'thỏ',
  raccoon: 'gấu trúc',
  rat: 'chuột',
  rattlesnake: 'rắn chuông',
  rhinoceros: 'tê giác',
  robin: 'robin',
  rooster: 'gà trống',
  scorpion: 'bọ cạp',
  shark: 'cá mập',
  sheep: 'cừu',
  swan: 'thiên nga',
  tiger: 'hổ',
  turkey: 'gà tây',
  wolf: 'chó sói',
  yak: 'yak',
  zebra: 'ngựa vằn',
};
