export const getFileNames = (context) => {
  const req = context;
  const fileNames = req.keys().map((key) => {
    const name = key.match(/(\w+-*)/g).slice(0, -1).join('');

    return name.toLowerCase();
  });

  return fileNames;
};

export const getFilePath = (context) => {
  const req = context;
  const fileNames = req.keys().map((key) => key
    .replace(/\/\w+.vue/g, '')
    .replace('./', '')
    .toLowerCase());

  return fileNames;
};

export const getFullFileNames = (context) => {
  const req = context;
  const fileNames = req.keys().map((key) => {
    const name = key.replace('./', '');
    return name.toLowerCase();
  });

  return fileNames;
};
