import i18nISO from '@cospired/i18n-iso-languages';
import en from '@cospired/i18n-iso-languages/langs/en.json';
import de from '@cospired/i18n-iso-languages/langs/de.json';


const languages = [en, de];

languages.forEach((lang) => i18nISO.registerLocale(lang));

export default {
  install: (app) => {
    Object.defineProperty(app.config.globalProperties, 'i18nISO', { value: i18nISO });

    app.provide('i18nISO', i18nISO);
  },
};
