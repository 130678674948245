module.exports = {
  'african-grey-parrot': 'afrika-abu-abu-nuri',
  alligator: 'buaya',
  alpaca: 'alpaca',
  anteater: 'trenggiling',
  antelope: 'antelop',
  ape: 'kera',
  bat: 'kelelawar',
  bee: 'lebah',
  'bowhead-whale': 'paus kepala busur',
  buffalo: 'kerbau',
  butterfly: 'kupu-kupu',
  camel: 'unta',
  cat: 'kucing',
  chicken: 'ayam',
  cow: 'sapi',
  crow: 'gagak',
  dinosaur: 'dinosaurus',
  dog: 'anjing',
  dove: 'merpati',
  duck: 'bebek',
  elephant: 'gajah',
  falcon: 'elang',
  ferret: 'musang',
  frog: 'katak',
  giraffe: 'jerapah',
  'guinea-pig': 'kelinci percobaan',
  hedgehog: 'landak',
  hippopotamus: 'kuda nil',
  horse: 'kuda',
  'humpback-whale': 'paus bungkuk',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-naga',
  leopard: 'macan tutul',
  lion: 'singa',
  lizard: 'kadal',
  moose: 'rusa',
  otter: 'berang-berang',
  owl: 'burung hantu',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'babi',
  rabbit: 'kelinci',
  raccoon: 'rakun',
  rat: 'tikus',
  rattlesnake: 'ular derik',
  rhinoceros: 'badak',
  robin: 'robin',
  rooster: 'ayam jantan',
  scorpion: 'kalajengking',
  shark: 'hiu',
  sheep: 'domba',
  swan: 'angsa',
  tiger: 'harimau',
  turkey: 'kalkun',
  wolf: 'serigala',
  yak: 'yak',
  zebra: 'zebra',
};
