<template>
  <div class="flex flex-col sm:flex-row sm:justify-center items-center pt-4 sm:pt-8 smgap-2 sm:gap-4 text-xl text-white">
    <div>
      <span class="pr-2">{{ $t('app.rounds') }}:</span>
      <span data-test-id="game_info__rounds">{{ rounds }}</span>
      <span class="hidden sm:inline sm:px-4 sm:pl-8">|</span>
    </div>
    <div>
      <span class="pr-2">{{ $t('app.trials') }}:</span>
      <span data-test-id="game_info__trials">{{ trials }}</span>
      <span class="hidden sm:inline sm:px-4 sm:pl-8">|</span>
    </div>
    <div class="text-white text-center" data-test-id="game_info__start_timer">
      <StopWatch
        :start="startTimer"
        @update:time="$emit('update:time', $event)" />
    </div>
  </div>
</template>

<script>
import StopWatch from '@/components/shared/stop_watch';

export default {
  name: 'GameControls',
  components: {
    StopWatch,
  },
  props: {
    rounds: {
      type: Number,
      required: true,
    },
    trials: {
      type: Number,
      required: true,
    },
    startTimer: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:time'],
};
</script>
