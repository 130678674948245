module.exports = {
  'african-grey-parrot': 'african-grey-parrot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'peʻa',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'pafalo',
  butterfly: 'butterfly',
  camel: 'kamela',
  cat: 'pusi',
  chicken: 'moa',
  cow: 'povi',
  crow: 'moa',
  dinosaur: 'dinosaur',
  dog: 'maile',
  dove: 'lupe',
  duck: 'pato',
  elephant: 'elefane',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'rane',
  giraffe: 'giraffe',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'hedgehog',
  hippopotamus: 'hippopotamus',
  horse: 'solofanua',
  'humpback-whale': 'tafolā-tafola',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'nameri',
  lion: 'leona',
  lizard: 'pili',
  moose: 'moose',
  otter: 'otter',
  owl: 'lulu',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'puaa',
  rabbit: 'lapiti',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'rhinoceros',
  robin: 'robin',
  rooster: 'moa',
  scorpion: 'akarava',
  shark: 'malie',
  sheep: 'mamoe',
  swan: 'swan',
  tiger: 'tiger',
  turkey: 'pipi',
  wolf: 'luko',
  yak: 'yak',
  zebra: 'zebra',
};
