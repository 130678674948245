module.exports = {
  'african-grey-parrot': 'afrikansk-grå-papegøye',
  alligator: 'alligator',
  alpaca: 'alpakka',
  anteater: 'maurseter',
  antelope: 'antilope',
  ape: 'ape',
  bat: 'flaggermus',
  bee: 'bee',
  'bowhead-whale': 'bowhead-hval',
  buffalo: 'bøffel',
  butterfly: 'sommerfugl',
  camel: 'kamel',
  cat: 'katt',
  chicken: 'kylling',
  cow: 'ku',
  crow: 'kråke',
  dinosaur: 'dinosaur',
  dog: 'hund',
  dove: 'due',
  duck: 'and',
  elephant: 'elefant',
  falcon: 'falk',
  ferret: 'ilder',
  frog: 'frosk',
  giraffe: 'sjiraff',
  'guinea-pig': 'marsvin',
  hedgehog: 'pinnsvin',
  hippopotamus: 'flodhest',
  horse: 'hest',
  'humpback-whale': 'knølhval',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-drage',
  leopard: 'leopard',
  lion: 'løve',
  lizard: 'øgle',
  moose: 'elg',
  otter: 'oter',
  owl: 'ugle',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'gris',
  rabbit: 'kanin',
  raccoon: 'vaskebjørn',
  rat: 'rotte',
  rattlesnake: 'klapperslange',
  rhinoceros: 'neshorn',
  robin: 'robin',
  rooster: 'hane',
  scorpion: 'skorpion',
  shark: 'hai',
  sheep: 'sau',
  swan: 'svane',
  tiger: 'tiger',
  turkey: 'kalkun',
  wolf: 'ulv',
  yak: 'yak',
  zebra: 'sebra',
};
