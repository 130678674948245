module.exports = {
  'african-grey-parrot': 'अफ्रीकी-ग्रे-तोता',
  alligator: 'मगरमच्छ',
  alpaca: 'अल्पाका',
  anteater: 'एंटीटर',
  antelope: 'मृग',
  ape: 'एप',
  bat: 'बल्ले',
  bee: 'मधुमक्खी',
  'bowhead-whale': 'धनुष-व्हेल',
  buffalo: 'भैंस',
  butterfly: 'तितली',
  camel: 'ऊंट',
  cat: 'बिल्ली',
  chicken: 'चिकन',
  cow: 'गाय',
  crow: 'कौवा',
  dinosaur: 'डायनासोर',
  dog: 'कुत्ता',
  dove: 'कबूतर',
  duck: 'बतख',
  elephant: 'हाथी',
  falcon: 'बाज़',
  ferret: 'फेरेट',
  frog: 'मेंढक',
  giraffe: 'जिराफ़',
  'guinea-pig': 'गिनी-पिग',
  hedgehog: 'हेजहोग',
  hippopotamus: 'हिप्पोपोटामस',
  horse: 'घोड़ा',
  'humpback-whale': 'हंपबैक-व्हेल',
  hyena: 'हाइना',
  'komodo-dragon': 'कोमोडो-ड्रैगन',
  leopard: 'तेंदुआ',
  lion: 'शेर',
  lizard: 'छिपकली',
  moose: 'मूस',
  otter: 'ओटर',
  owl: 'उल्लू',
  panda: 'पांडा',
  penguin: 'पेंगुइन',
  pig: 'सुअर',
  rabbit: 'खरगोश',
  raccoon: 'रेकून',
  rat: 'चूहा',
  rattlesnake: 'रैटलस्नेक',
  rhinoceros: 'गैंडा',
  robin: 'रॉबिन',
  rooster: 'मुर्गा',
  scorpion: 'बिच्छू',
  shark: 'शार्क',
  sheep: 'भेड़',
  swan: 'हंस',
  tiger: 'बाघ',
  turkey: 'टर्की',
  wolf: 'भेड़िया',
  yak: 'याक',
  zebra: 'ज़ेबरा',
};
