import { updateDoc, arrayUnion, deleteField } from 'firebase/firestore/lite';
import { getDocRef } from '@/plugins/firebase/methods';

export const fireUpdate = (docRef, field, data) => {
  const path = getDocRef(docRef);

  try {
    return updateDoc(path, {
      [field]: arrayUnion(data),
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fireDeleteField = (docRef, field, data) => {
  const path = getDocRef(docRef);

  try {
    return updateDoc(path, {
      [field]: deleteField(),
    });
  } catch (error) {
    throw new Error(error);
  }
};
