module.exports = {
  'african-grey-parrot': 'африканско-сиво-папагал',
  alligator: 'алигатор',
  alpaca: 'алпака',
  anteater: 'мравојад',
  antelope: 'антилопа',
  ape: 'мајмун',
  bat: 'лилјак',
  bee: 'пчела',
  'bowhead-whale': 'кит со поклон',
  buffalo: 'бивол',
  butterfly: 'пеперутка',
  camel: 'камила',
  cat: 'мачка',
  chicken: 'кокошка',
  cow: 'крава',
  crow: 'врана',
  dinosaur: 'диносаурус',
  dog: 'куче',
  dove: 'гулаб',
  duck: 'патка',
  elephant: 'слон',
  falcon: 'сокол',
  ferret: 'порове',
  frog: 'жаба',
  giraffe: 'жирафа',
  'guinea-pig': 'заморче',
  hedgehog: 'еж',
  hippopotamus: 'нилски коњ',
  horse: 'коњ',
  'humpback-whale': 'грбав кит',
  hyena: 'хиена',
  'komodo-dragon': 'комодо-змеј',
  leopard: 'леопард',
  lion: 'лав',
  lizard: 'гуштер',
  moose: 'лос',
  otter: 'видра',
  owl: 'був',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'свиња',
  rabbit: 'зајак',
  raccoon: 'ракун',
  rat: 'стаорец',
  rattlesnake: 'tвечарка',
  rhinoceros: 'носорог',
  robin: 'робин',
  rooster: 'петел',
  scorpion: 'скорпија',
  shark: 'ајкула',
  sheep: 'овца',
  swan: 'лебед',
  tiger: 'тигар',
  turkey: 'мисирка',
  wolf: 'волк',
  yak: 'јак',
  zebra: 'зебра',
};
