import * as firestoreMethods from './methods';


export default {
  install: (app) => {
    Object.defineProperty(app.config.globalProperties, 'firestore', { value: firestoreMethods });

    app.provide('firestore', firestoreMethods);
  },
};
