<template>
  <GameBack @click="$router.push(pathTo.home)" />
  <GameHeadline>
    <template #title>
      {{ $t('gameList.animal_sounds.title') }}
    </template>

    <template #subtitle>
      {{ $t('pages.animalSounds.start.subTitle') }}
    </template>

    <template #hint>
      <GameHintVolumeUp />
    </template>
  </GameHeadline>

  <div class="flex justify-center mt-1 mb-3">
    <Button
      @click="switchView"
      size="small" color="white"
      class="flex justify-center mt-2 mb-6"
      data-test-id="switch_view">
      {{ $t('pages.animalSounds.start.switch', {
        view: view === 'grid'
          ? $t('pages.animalSounds.start.carousel')
          : $t('pages.animalSounds.start.grid')
      }) }}
    </Button>
  </div>
  <div
    v-if="view === 'carousel'"
    data-test-id="animal_carousel">
    <AnimalCarousel :animals="animals" />
  </div>
  <div
    v-else-if="view === 'grid'"
    class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-12"
    data-test-id="animal_grid">
    <Animal
      v-for="animal in animals" :key="animal.name"
      :animal="animal"
      :selectable="false"
      highlightable
      show-name
      pulse-animation
      pulse-text-color="text-yellow-200" />
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import GameBack from '@/components/shared/game_back';
import GameHeadline from '@/components/shared/game_headline';
import Animal from '@/components/shared/animal';
import Button from '@/components/shared/basic/Button';
import AnimalCarousel from '@/components/shared/animal_carousel';
import GameHintVolumeUp from '@/components/shared/game_hint/GameHintVolumeUp';
import animalList from '@/config/animalList';
import { pathTo } from '@/plugins/router/helper';

export default {
  name: 'AnimalSoundsStart',
  components: {
    GameBack,
    GameHeadline,
    Animal,
    Button,
    AnimalCarousel,
    GameHintVolumeUp,
  },
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t('meta.animalSounds.start.title'),
      meta: [
        { name: 'description', content: t('meta.animalSounds.start.description') },
      ],
    });

    const view = ref('grid');

    const switchView = () => {
      view.value = view.value === 'grid' ? 'carousel' : 'grid';
    };

    return {
      pathTo,
      animals: animalList,
      view,
      switchView,
    };
  },
};
</script>
