module.exports = {
  'african-grey-parrot': 'африкан-соры-тоту',
  alligator: 'аллигатор',
  alpaca: 'алпака',
  anteater: 'антеатер',
  antelope: 'антилопа',
  ape: 'маймыл',
  bat: 'бат',
  bee: 'умарта',
  'bowhead-whale': 'баш-кит',
  buffalo: 'буфало',
  butterfly: 'күбәләк',
  camel: 'дөя',
  cat: 'мәче',
  chicken: 'тавык',
  cow: 'сыер',
  crow: 'карга',
  dinosaur: 'динозавр',
  dog: 'эт',
  dove: 'күгәрчен',
  duck: 'үрдәк',
  elephant: 'фил',
  falcon: 'шумкар',
  ferret: 'феррет',
  frog: 'бака',
  giraffe: 'жираф',
  'guinea-pig': 'гвинея',
  hedgehog: 'керпе',
  hippopotamus: 'иппопотам',
  horse: 'ат',
  'humpback-whale': 'кит-кит',
  hyena: 'гена',
  'komodo-dragon': 'комодо-аждаһа',
  leopard: 'барс',
  lion: 'арыслан',
  lizard: 'кәлтәләр',
  moose: 'сазлык',
  otter: 'оттер',
  owl: 'байка',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'дуңгыз',
  rabbit: 'куян',
  raccoon: 'ракун',
  rat: 'тычкан',
  rattlesnake: 'чабышкы',
  rhinoceros: 'керчек',
  robin: 'робин',
  rooster: 'әтәч',
  scorpion: 'чаян',
  shark: 'акула',
  sheep: 'сарык',
  swan: 'аккош',
  tiger: 'юлбарыс',
  turkey: 'күркә',
  wolf: 'бүре',
  yak: 'як',
  zebra: 'зебра',
};
