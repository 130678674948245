<template>
  <section class="p-4 flex items-center flex-col">
    <h1 class="text-white">
      {{ $t('components.highscoreList.title') }}
    </h1>
    <div class="text-white self-start md:self-auto">
      <Button @click="$router.push(backRoute)" size="small" color="white" data-test-id="highscore_list__button">
        {{ $t('components.highscoreList.back2Home') }}
      </Button>
    </div>
    <table class="mt-3 w-full md:w-8/12 lg:w-6/12 divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            v-for="item in ['rank', 'name', 'rounds', 'trials', 'time']" :key="item"
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(user, index) in orderedHighscoreList" :key="`highscorelist-${index}`">
          <td
            data-test-id="highscore_list__index"
            class="px-6 py-4 font-medium text-gray-900">
            {{ index + 1 }}
          </td>
          <td
            data-test-id="highscore_list__name"
            class="px-6 py-4 font-medium text-gray-900">
            {{ user.name }}
          </td>
          <td
            data-test-id="highscore_list__rounds"
            class="px-6 py-4 font-medium text-gray-900">
            {{ user.rounds }}
          </td>
          <td
            data-test-id="highscore_list__trials"
            class="px-6 py-4 font-medium text-gray-900">
            {{ user.trials }}
          </td>
          <td
            data-test-id="highscore_list__time"
            class="px-6 py-4 font-medium text-gray-900">
            {{ timeToString(user.time) }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import {
  ref, toRefs, onMounted,
  inject,
} from 'vue';
import Button from '@/components/shared/basic/Button';
import { timeToString } from '@/helper/timeToString';

export default {
  name: 'Highscore',
  components: {
    Button,
  },
  props: {
    documentPath: {
      type: Array,
      required: true,
    },
    backRoute: {
      type: String,
      required: true,
      default: '/',
    },
  },
  setup(props) {
    const { orderBy } = inject('utils');
    const { getDataFrom } = inject('firestore');

    const highscoreList = ref([]);
    const orderedHighscoreList = ref([]);
    const { documentPath } = toRefs(props);

    const getOrderedHighscoreList = async() => {
      highscoreList.value = await (await getDataFrom(documentPath.value)).users;
      orderedHighscoreList.value = orderBy(highscoreList.value, ['rounds', 'trials', 'time'], ['desc', 'desc', 'asc']);
    };

    onMounted(async () => await getOrderedHighscoreList());

    return {
      orderedHighscoreList,
      timeToString,
    };
  },
};
</script>
