module.exports = {
  'african-grey-parrot': 'african-grey-papoušek',
  alligator: 'aligátor',
  alpaca: 'alpaka',
  anteater: 'mravenečník',
  antelope: 'antilopa',
  ape: 'opice',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buvol',
  butterfly: 'motýl',
  camel: 'velbloud',
  cat: 'kočka',
  chicken: 'kuře',
  cow: 'kráva',
  crow: 'vrána',
  dinosaur: 'dinosaurus',
  dog: 'pes',
  dove: 'holubice',
  duck: 'kachna',
  elephant: 'slon',
  falcon: 'sokol',
  ferret: 'fretka',
  frog: 'žába',
  giraffe: 'žirafa',
  'guinea-pig': 'morče',
  hedgehog: 'ježek',
  hippopotamus: 'hroch',
  horse: 'kůň',
  'humpback-whale': 'keporkak',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-drak',
  leopard: 'leopard',
  lion: 'lev',
  lizard: 'ještěr',
  moose: 'los',
  otter: 'vydra',
  owl: 'sova',
  panda: 'panda',
  penguin: 'tučňák',
  pig: 'prase',
  rabbit: 'králík',
  raccoon: 'mýval',
  rat: 'krysa',
  rattlesnake: 'chřestýš',
  rhinoceros: 'rhinoceros',
  robin: 'robin',
  rooster: 'rooster',
  scorpion: 'scorpion',
  shark: 'shark',
  sheep: 'ovce',
  swan: 'labuť',
  tiger: 'tygr',
  turkey: 'krocan',
  wolf: 'vlk',
  yak: 'jak',
  zebra: 'zebra',
};
