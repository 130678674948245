module.exports = {
  'african-grey-parrot': 'ヨウム',
  alligator: 'ワニ',
  alpaca: 'アルパカ',
  anteater: 'アリクイ',
  antelope: 'カモシカ',
  ape: '類人猿',
  bat: 'コウモリ',
  bee: 'ハチ',
  'bowhead-whale': 'ホッキョククジラ',
  buffalo: 'バッファロー',
  butterfly: 'バタフライ',
  camel: 'ラクダ',
  cat: '猫',
  chicken: '鶏',
  cow: '牛',
  crow: 'カラス',
  dinosaur: '恐竜',
  dog: '犬',
  dove: '鳩',
  duck: 'アヒル',
  elephant: '象',
  falcon: 'ハヤブサ',
  ferret: 'フェレット',
  frog: 'カエル',
  giraffe: 'キリン',
  'guinea-pig': 'モルモット',
  hedgehog: 'ハリネズミ',
  hippopotamus: 'カバ',
  horse: '馬',
  'humpback-whale': 'ザトウクジラ',
  hyena: 'ハイエナ',
  'komodo-dragon': 'コモドオオトカゲ',
  leopard: 'ヒョウ',
  lion: 'ライオン',
  lizard: 'トカゲ',
  moose: 'ムース',
  otter: 'カワウソ',
  owl: 'フクロウ',
  panda: 'パンダ',
  penguin: 'ペンギン',
  pig: '豚',
  rabbit: 'ウサギ',
  raccoon: 'アライグマ',
  rat: 'ラット',
  rattlesnake: 'ガラガラヘビ',
  rhinoceros: 'サイ',
  robin: 'ロビン',
  rooster: 'オンドリ',
  scorpion: 'サソリ',
  shark: 'サメ',
  sheep: '羊',
  swan: '白鳥',
  tiger: '虎',
  turkey: '七面鳥',
  wolf: 'オオカミ',
  yak: 'ヤク',
  zebra: 'シマウマ',
};
