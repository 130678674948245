export const timeToString = (time) => {
  const needZero = (val) => (val < 10 ? `0${ val}` : val);

  const diffInHrs = time / 3600000;
  const hh = needZero(Math.floor(diffInHrs));

  const diffInMin = (diffInHrs - hh) * 60;
  const mm = needZero(Math.floor(diffInMin));

  const diffInSec = (diffInMin - mm) * 60;
  const ss = needZero(Math.floor(diffInSec));

  return `${hh}:${mm}:${ss}`;
};
