module.exports = {
  'african-grey-parrot': 'afrikansk-grå-papegøje',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antilope',
  ape: 'abe',
  bat: 'flagermus',
  bee: 'bi',
  'bowhead-whale': 'bowhead-hval',
  buffalo: 'bøffel',
  butterfly: 'sommerfugl',
  camel: 'kamel',
  cat: 'kat',
  chicken: 'kylling',
  cow: 'ko',
  crow: 'krage',
  dinosaur: 'dinosaur',
  dog: 'hund',
  dove: 'due',
  duck: 'and',
  elephant: 'elefant',
  falcon: 'falk',
  ferret: 'ilder',
  frog: 'frø',
  giraffe: 'giraf',
  'guinea-pig': 'marsvin',
  hedgehog: 'pindsvin',
  hippopotamus: 'flodhest',
  horse: 'hest',
  'humpback-whale': 'pukkelhval',
  hyena: 'hyæne',
  'komodo-dragon': 'komodo-drage',
  leopard: 'leopard',
  lion: 'løve',
  lizard: 'firben',
  moose: 'elg',
  otter: 'odder',
  owl: 'ugle',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'gris',
  rabbit: 'kanin',
  raccoon: 'vaskebjørn',
  rat: 'rotte',
  rattlesnake: 'klapperslange',
  rhinoceros: 'næsehorn',
  robin: 'robin',
  rooster: 'hane',
  scorpion: 'skorpion',
  shark: 'haj',
  sheep: 'får',
  swan: 'svane',
  tiger: 'tiger',
  turkey: 'kalkun',
  wolf: 'ulv',
  yak: 'yak',
  zebra: 'zebra',
};
