module.exports = {
  'african-grey-parrot': 'африканско-сив папагал',
  alligator: 'алигатор',
  alpaca: 'алпака',
  anteater: 'мравояд',
  antelope: 'антилопа',
  ape: 'маймуна',
  bat: 'прилеп',
  bee: 'пчела',
  'bowhead-whale': 'лък-кит',
  buffalo: 'бивол',
  butterfly: 'пеперуда',
  camel: 'камила',
  cat: 'котка',
  chicken: 'пиле',
  cow: 'крава',
  crow: 'врана',
  dinosaur: 'динозавър',
  dog: 'куче',
  dove: 'гълъб',
  duck: 'патица',
  elephant: 'слон',
  falcon: 'сокол',
  ferret: 'пор',
  frog: 'жаба',
  giraffe: 'жираф',
  'guinea-pig': 'морско свинче',
  hedgehog: 'таралеж',
  hippopotamus: 'хипопотам',
  horse: 'кон',
  'humpback-whale': 'гърбав кит',
  hyena: 'хиена',
  'komodo-dragon': 'комодо-дракон',
  leopard: 'леопард',
  lion: 'лъв',
  lizard: 'гущер',
  moose: 'лос',
  otter: 'видра',
  owl: 'бухал',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'прасе',
  rabbit: 'заек',
  raccoon: 'миеща мечка',
  rat: 'плъх',
  rattlesnake: 'гърмяща змия',
  rhinoceros: 'носорог',
  robin: 'червеник',
  rooster: 'петел',
  scorpion: 'скорпион',
  shark: 'акула',
  sheep: 'овца',
  swan: 'лебед',
  tiger: 'тигър',
  turkey: 'пуйка',
  wolf: 'вълк',
  yak: 'як',
  zebra: 'зебра',
};
