<template>
  <div data-test-id="game_end__container">
    <Modal
      :show="playerWon || playerLost"
      :title="playerWon ? $t('components.gameEnd.title.won') : $t('components.gameEnd.title.lost')">
      <div v-if="playerWon">
        {{ $t('components.gameEnd.success', {
          amountRounds: rounds,
          rounds: $t('components.gameEnd.rounds', rounds),
          amountTrials: trials,
          trials: $t('components.gameEnd.trials', trials)
        }) }}
      </div>
      <div v-if="playerLost">
        {{ $t('components.gameEnd.failure', {
          counter: counter
        }) }}
      </div>
      <template #footer>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center sm:flex-row-reverse text-center">
          <Button
            color="green"
            size="small-medium"
            @click="$emit('reset')"
            data-test-id="game_end__reset">
            {{ $t('app.restart') }}
          </Button>
          <div class="flex justify-center items-center sm:px-6 py-2 sm:py-0 mt-0">or</div>
          <Button
            color="blue"
            size="small-medium"
            @click="showHighscore = true"
            data-test-id="game_end__highscore_show">
            {{ $t('components.gameEnd.highscore') }}
          </Button>
        </div>
        <div
          v-if="showHighscore"
          class="flex items-center flex-col sm:flex-row justify-center p-4"
          data-test-id="game_end__highscore_form">
          <label for="name" class="pr-3">{{ $t('app.name') }}</label>
          <input
            v-model="name"
            autocomplete="off"
            type="text" name="name" id="name" class="input w-full sm:w-6/12"
            data-test-id="game_end__highscore_form_input">
          <Button
            color="blue"
            size="small-medium"
            class="sm:ml-4 mt-4 sm:mt-0"
            @click="submitHighscore"
            data-test-id="game_end__highscore_form_submit">
            {{ $t('app.submit') }}
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/shared/basic/Modal';
import Button from '@/components/shared/basic/Button';
import { fireUpdate } from '@/composables/firestore';


export default {
  name: 'GameEnd',
  components: {
    Modal,
    Button,
  },
  props: {
    playerWon: {
      type: Boolean,
      required: true,
    },
    playerLost: {
      type: Boolean,
      required: true,
    },
    rounds: {
      type: Number,
      required: true,
    },
    trials: {
      type: Number,
      required: true,
    },
    counter: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    documentPath: {
      type: Array,
      required: true,
    },
    highscoreRoute: {
      type: String,
      required: true,
      default: '/',
    },
  },
  emits: ['reset'],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const showHighscore = ref(false);
    const name = ref('');

    const {
      documentPath, rounds, trials, time, highscoreRoute,
    } = toRefs(props);

    const submitHighscore = async() => {
      if (!name.value) {
        store.dispatch('current/setSnackbar', {
          text: t('app.invalid.name_too_short'),
        });

        return;
      }

      const data = {
        name: name.value,
        rounds: rounds.value,
        trials: trials.value,
        time: time.value,
      };

      try {
        await fireUpdate(documentPath.value, 'users', data);

        router.push(highscoreRoute.value);
      } catch {
        store.dispatch('current/setSnackbar', {
          text: t('components.gameEnd.error'),
        });
      }
    };

    return {
      showHighscore,
      name,
      submitHighscore,
    };
  },
};
</script>
