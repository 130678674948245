module.exports = {
  'african-grey-parrot': 'afrikai-szürke-papagáj',
  alligator: 'aligátor',
  alpaca: 'alpaka',
  anteater: 'hangyász',
  antelope: 'antilop',
  ape: 'majom',
  bat: 'denevér',
  bee: 'méh',
  'bowhead-whale': 'íjfej-bálna',
  buffalo: 'bivaly',
  butterfly: 'pillangó',
  camel: 'teve',
  cat: 'macska',
  chicken: 'csirke',
  cow: 'tehén',
  crow: 'varjú',
  dinosaur: 'dinoszaurusz',
  dog: 'kutya',
  dove: 'galamb',
  duck: 'kacsa',
  elephant: 'elefánt',
  falcon: 'sólyom',
  ferret: 'görény',
  frog: 'béka',
  giraffe: 'zsiráf',
  'guinea-pig': 'tengerimalac',
  hedgehog: 'sündisznó',
  hippopotamus: 'víziló',
  horse: 'ló',
  'humpback-whale': 'púpos bálna',
  hyena: 'hiéna',
  'komodo-dragon': 'komodó-sárkány',
  leopard: 'leopárd',
  lion: 'oroszlán',
  lizard: 'gyík',
  moose: 'jávorszarvas',
  otter: 'vidra',
  owl: 'bagoly',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'sertés',
  rabbit: 'nyúl',
  raccoon: 'mosómedve',
  rat: 'patkány',
  rattlesnake: 'csörgőkígyó',
  rhinoceros: 'orrszarvú',
  robin: 'vörösbegy',
  rooster: 'kakas',
  scorpion: 'skorpió',
  shark: 'cápa',
  sheep: 'juh',
  swan: 'hattyú',
  tiger: 'tigris',
  turkey: 'pulyka',
  wolf: 'farkas',
  yak: 'jak',
  zebra: 'zebra',
};
