import { getFilePath } from '@/helper/files';

const HOME = 'home';
const pathNames = getFilePath(require.context('@/pages/', true, /\.(vue)$/i));
const formatName = (name) => name.replace(/\/+/g, '__');
const formatRoute = (route) => route.replace(/_/g, '-').replace('/start', '');

const createDynamicPagePaths = () => {
  const paths = {};
  pathNames.forEach((path) => {
    paths[formatName(path)] = path === HOME ? '/' : `/${formatRoute(path)}`;
  });

  return paths;
};

export const pathTo = {
  ...createDynamicPagePaths(),
};

export const createDynamicRoutes = () => {
  const dynamicRoutes = pathNames.map((path) => {
    const route = {
      path: path === HOME ? pathTo.home : `/${formatRoute(path)}`,
      name: formatName(path),
      component: () => import(`@/pages/${path}`),
    };

    return route;
  });

  return dynamicRoutes;
};
