<template>
  <div class="p-4 text-center">
    <Button
      size="small"
      color="white"
      data-test-id="game_back">
      {{ $t('components.gameBack.back') }}
    </Button>
  </div>
</template>

<script>
import Button from '@/components/shared/basic/Button';

export default {
  name: 'GameBack',
  components: {
    Button,
  },
};
</script>
