<template>
  <div data-test-id="game_configuration__container">
    <Modal
      :show="show"
      :title="$t('components.gameConfiguration.title')"
      :save-btn="$t('app.save')"
      :disable-save-btn="!isValidConfiguration"
      @save="setConfiguration"
      @cancel="$emit('cancel')">
      <div v-if="inputType === 'input'">
        <div class="flex flex-col items-start">
          <label for="rounds" class="text-base">
            {{ $t('components.gameConfiguration.setRounds') }}
          </label>
          <input
            v-model.number="rounds"
            type="number" min="1" name="rounds" id="rounds" class="input w-full sm:w-6/12"
            data-test-id="game_configuration__input">
        </div>
        <div
          :class="{invisible: isValidConfiguration}"
          class="text-red-500 pt-4 text-sm"
          data-test-id="game_configuration__input_validation">
          {{ $t('components.gameConfiguration.validate') }}
        </div>
      </div>
      <div
        v-else-if="inputType === 'select'"
        data-test-id="game_configuration__select">
        <Select
          v-model:select="rounds"
          :items="totalRounds"
          :label="$t('components.gameConfiguration.setRounds')"
          selected="20" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { ref, toRefs, computed } from 'vue';
import Modal from '@/components/shared/basic/Modal';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Select from '@/components/shared/basic/Select';
import { animalListLength } from '@/config/animalList';

export default {
  name: 'GameConfiguration',
  components: {
    Modal,
    Select,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    gameRoute: {
      type: String,
      required: true,
      default: '/',
    },
    inputType: {
      type: String,
      default: 'input',
    },
  },
  emits: ['cancel'],
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const rounds = ref(20);
    const { gameRoute } = toRefs(props);

    const isValidConfiguration = computed(() => rounds.value > 0);
    const totalRounds = computed(() => {
      const list = [];
      for (let n = 1; n <= animalListLength; n += 1) {
        list.push(n);
      }
      return list;
    });

    const setRounds = (val) => store.dispatch('current/setRounds', val);
    const setConfiguration = () => {
      if (!isValidConfiguration.value) return;

      setRounds(rounds.value);
      router.push(gameRoute.value);
    };

    return {
      rounds,
      isValidConfiguration,
      totalRounds,
      setConfiguration,
    };
  },
};
</script>
