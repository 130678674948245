import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore/lite';
import firebaseConfig from './config';


// fails if .env.local is missing or wrong firebaseConfig
if (Object.values(firebaseConfig).some((values) => !values)) {
  throw new Error('firebaseConfig values are undefined');
}

const firebase = initializeApp(firebaseConfig);

isSupported().then((support) => {
  if (support) {
    getAnalytics(firebase);
  }
});

export const firestore = getFirestore(firebase);
