module.exports = {
  'african-grey-parrot': 'աֆրիկյան-մոխրագույն-թութակ',
  alligator: 'ալիգատոր',
  alpaca: 'ալպակա',
  anteater: 'մրջյունակեր',
  antelope: 'անտիլոպա',
  ape: 'կապիկ',
  bat: 'չղջիկ',
  bee: 'մեղու',
  'bowhead-whale': 'ծիածան-կետ',
  buffalo: 'գոմեշ',
  butterfly: 'թիթեռ',
  camel: 'ուղտ',
  cat: 'կատու',
  chicken: 'հավ',
  cow: 'կով',
  crow: 'ագռավ',
  dinosaur: 'դինոզավր',
  dog: 'շուն',
  dove: 'աղավնի',
  duck: 'բադ',
  elephant: 'փիղ',
  falcon: 'բազե',
  ferret: 'գայլուկ',
  frog: 'գորտ',
  giraffe: 'ընձուղտ',
  'guinea-pig': 'ծովախոզուկ',
  hedgehog: 'ոզնի',
  hippopotamus: 'գետաձի',
  horse: 'ձի',
  'humpback-whale': 'թև-կետ',
  hyena: 'բորենի',
  'komodo-dragon': 'կոմոդո-վիշապ',
  leopard: 'ընձառյուծ',
  lion: 'առյուծ',
  lizard: 'մողես',
  moose: 'մոս',
  otter: 'ջրասամույր',
  owl: 'բու',
  panda: 'պանդա',
  penguin: 'պինգվին',
  pig: 'խոզ',
  rabbit: 'նապաստակ',
  raccoon: 'ջրարջ',
  rat: 'առնետ',
  rattlesnake: 'ճռռող օձ',
  rhinoceros: 'ռնգեղջյուր',
  robin: 'ռոբին',
  rooster: 'աքաղաղ',
  scorpion: 'կարիճ',
  shark: 'շնաձուկ',
  sheep: 'ոչխար',
  swan: 'կարապ',
  tiger: 'վագր',
  turkey: 'հնդկահավ',
  wolf: 'գայլ',
  yak: 'յակ',
  zebra: 'զեբրա',
};
