module.exports = {
  'african-grey-parrot': 'afrikkalainen harmaa-papukaija',
  alligator: 'alligaattori',
  alpaca: 'alpakka',
  anteater: 'muurahaiskarhuja',
  antelope: 'antilooppi',
  ape: 'apina',
  bat: 'lepakko',
  bee: 'mehiläinen',
  'bowhead-whale': 'jousivala',
  buffalo: 'puhveli',
  butterfly: 'perhonen',
  camel: 'kameli',
  cat: 'kissa',
  chicken: 'kana',
  cow: 'lehmä',
  crow: 'varis',
  dinosaur: 'dinosaurus',
  dog: 'koira',
  dove: 'kyyhky',
  duck: 'ankka',
  elephant: 'norsu',
  falcon: 'haukka',
  ferret: 'fretti',
  frog: 'sammakko',
  giraffe: 'kirahvi',
  'guinea-pig': 'marsu',
  hedgehog: 'siili',
  hippopotamus: 'virtahepo',
  horse: 'hevonen',
  'humpback-whale': 'ryhävalas',
  hyena: 'hyeena',
  'komodo-dragon': 'komodo-lohikäärme',
  leopard: 'leopardi',
  lion: 'leijona',
  lizard: 'lisko',
  moose: 'hirvi',
  otter: 'saukko',
  owl: 'pöllö',
  panda: 'panda',
  penguin: 'pingviini',
  pig: 'sika',
  rabbit: 'kani',
  raccoon: 'pesukarhu',
  rat: 'rotta',
  rattlesnake: 'kalkkarokäärme',
  rhinoceros: 'sarvikuono',
  robin: 'punarinta',
  rooster: 'kukko',
  scorpion: 'skorpioni',
  shark: 'hai',
  sheep: 'lammas',
  swan: 'joutsen',
  tiger: 'tiikeri',
  turkey: 'kalkkuna',
  wolf: 'susi',
  yak: 'jaki',
  zebra: 'seepra',
};
