module.exports = {
  'african-grey-parrot': 'afričko-siva papiga',
  alligator: 'aligator',
  alpaca: 'alpaka',
  anteater: 'mravojed',
  antelope: 'antilopa',
  ape: 'majmun',
  bat: 'šišmiš',
  bee: 'pčela',
  'bowhead-whale': 'kit-pramac',
  buffalo: 'bivoli',
  butterfly: 'leptir',
  camel: 'deva',
  cat: 'mačka',
  chicken: 'piletina',
  cow: 'krava',
  crow: 'vrana',
  dinosaur: 'dinosaurus',
  dog: 'pas',
  dove: 'golubica',
  duck: 'patka',
  elephant: 'slon',
  falcon: 'sokol',
  ferret: 'tvor',
  frog: 'žaba',
  giraffe: 'žirafa',
  'guinea-pig': 'zamorac',
  hedgehog: 'jež',
  hippopotamus: 'nilski konj',
  horse: 'konj',
  'humpback-whale': 'grbavi kit',
  hyena: 'hijena',
  'komodo-dragon': 'komodo-zmaj',
  leopard: 'leopard',
  lion: 'lav',
  lizard: 'gušter',
  moose: 'los',
  otter: 'vidra',
  owl: 'sova',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'svinja',
  rabbit: 'zec',
  raccoon: 'rakun',
  rat: 'štakor',
  rattlesnake: 'čegrtuša',
  rhinoceros: 'nosorog',
  robin: 'crvendać',
  rooster: 'pijetao',
  scorpion: 'škorpion',
  shark: 'morski pas',
  sheep: 'ovca',
  swan: 'labud',
  tiger: 'tigar',
  turkey: 'puretina',
  wolf: 'vuk',
  yak: 'jak',
  zebra: 'zebra',
};
