<template>
  <div class="flex justify-evenly pt-10">
    <slot name="start-btn">
      <Button
        @click="$emit('start')"
        color="green"
        :disabled="disableStartBtn"
        data-test-id="game_controls__start">
        {{ $t('app.start') }}
      </Button>
    </slot>

    <div
      class="flex items-center justify-center bg-blue-300 px-4 sm:px-8 rounded text-white"
      :class="{highlight_counter: highlightCounter}"
      data-test-id="game_controls__counter">
      {{ counter }}
    </div>

    <slot name="reset-btn">
      <Button
        @click="$emit('reset')"
        color="orange"
        data-test-id="game_controls__reset">
        {{ $t('app.reset') }}
      </Button>
    </slot>
  </div>
</template>

<script>
import Button from '@/components/shared/basic/Button';

export default {
  name: 'GameControls',
  components: {
    Button,
  },
  props: {
    counter: {
      type: Number,
      required: true,
    },
    disableStartBtn: {
      type: Boolean,
      default: false,
    },
    highlightCounter: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['start', 'reset'],
};
</script>

<style scoped>
.highlight_counter {
  @apply bg-yellow-300;

  box-shadow: 0 0 30px white;
}
</style>
