module.exports = {
  app: {
    title: 'Tier Spiele',
    description: `Kostenlose Lern-Mini-Spiele für Kinder mit über 50 Tierlauten. 
        Lass dein Kind sein/ ihr Gedächtnis trainieren, in dem es ausgewählte Tiere in einer zunehmend komplexeren Reihenfolge richtig ordnet (inklusive Blind-Modus),
        die Tiere in einem lustigen Quiz nur anhand ihrer Laute identifiziert oder einfach nur die Tierlaute abspielt und genießt. Jetzt ausprobieren!`,
    save: 'Speichern',
    cancel: 'Abbrechen',
    start: 'Start',
    reset: 'Zurücksetzen',
    restart: 'Neustart',
    name: 'Name',
    submit: 'Absenden',
    rounds: 'Runden',
    trials: 'Versuche',
    invalid: {
      name_too_short: 'Fehler: Name muss mind. 1 Buchstaben haben',
    },
  },
  gameList: {
    animals_in_order: {
      title: 'Tiere ordnen',
      text: `
        4 Tiere werden zufällig markiert. Merke dir die Reihenfolge und wiederhole sie exakt so wie sie angezeigt wurde.
        Jede Runde wird die Reihenfolge komplexer wodurch es immer schwieriger wird sie sich zu merken.
        Du kannst dir aus 50 Tieren 4 zum spielen auswählen und entscheiden wieviele Runden das Spiel dauern soll.
        `,
    },
    animals_in_order_blind: {
      title: 'Tiere ordnen - Blind-Modus',
      text: `
        Die gleiche Idee wie 'Tiere ordnen' mit dem kleinen Unterschied - Du siehst nichts.
        Du kannst die Tiere nur hören und musst dir die Reihenfolge ausschließlich anhand der jeweiligen Tierlaute merken.
        `,
    },
    animal_quiz: {
      title: 'Tier Quiz',
      text: 'Höre einen Tierlaut und rate zu welchem Tier dieser Laut gehört.',
    },
    animal_sounds: {
      title: 'Tierlaute',
      text: 'Dies ist kein Spiel sondern einfach nur die Möglichkeit sich alle Tierlaute nach Lust und Laune anzuhören.',
    },
  },
  components: {
    animalsInOrder: {
      title: 'Bitte wähle {amount} {more} {animal} zum spielen aus',
      more: 'weiteres | weitere ',
      animal: 'Tier | Tiere',
    },
    gameCard: {
      playNow: 'Jetzt spielen',
      comingSoon: 'Demnächst verfügbar',
    },
    gamePlay: {
      title: {
        blindMode: 'Zuhören',
        normalMode: 'Schauen',
        text: '{mode} und wiederholen',
      },
      subTitle: {
        blindMode: 'Höre die Tierlaute an und wiederhole',
        normalMode: 'Wiederhole',
        text: '{mode} die Tiere in der richtigen Reihenfolge. Jede Runde wird die Reihenfolge um eins erhöht.',
      },
      hint: {
        blindMode: 'notwendig',
        normalMode: 'optional',
        text: 'Ton ist {mode}',
      },
    },
    gameBack: {
      back: 'Zurück zur Spielauswahl',
    },
    gameConfiguration: {
      title: 'Konfiguriere wie du das Spiel spielen möchtest',
      setRounds: 'Rundenanzahl',
      validate: 'Rundenanzahl muss höher als 0 sein',
    },
    gameEnd: {
      title: {
        won: 'Gewonnen',
        lost: 'Verloren',
      },
      success: 'Herzlichen Glückwunsch. Du hast {amountRounds} {rounds} gelöst und noch {amountTrials} {trials} übrig',
      failure: `Oh-Oh. 
        Leider war das falsch und du hast keine Versuche mehr übrig. Aber immerhin hast du es bis zu Runde {counter} geschafft.`,
      highscore: 'Zur Bestenliste hinzufügen',
      rounds: 'Runde | Runden',
      trials: 'Versuch | Versuchen',
      error: 'Ein Fehler ist aufgetreten. Probiere es bitte noch einmal.',
    },
    gameHintVolumeUp: {
      soundRequired: 'Ton ist notwendig',
    },
    highscoreList: {
      title: 'Bestenliste',
      back2Home: 'Zurück zur Startseite',
    },
    stopWatch: {
      time: 'Zeit',
    },
    languageSelector: {
      title: 'Sprachauswahl',
      app: {
        select: 'App Sprache',
        flag: 'App',
      },
      animals: {
        select: 'Tier Sprache',
        flag: 'Tiere',
      },
    },
  },
  pages: {
    animalQuiz: {
      game: {
        title: 'Welches Tier macht diesen Laut?',
        subTitle: `
          Jede Runde wird ein anderer Tierlaut abgespielt und eine Auswhahl von 4 Tieren präsentiert.
          Rate zu welchem Tier der Laut gehört.`,
      },
    },
    animalSounds: {
      start: {
        subTitle: 'Höre über 50 verschiedene Tierlaute',
        switch: '{view} Ansicht',
        grid: 'Kachel',
        carousel: 'Karussel',
      },
    },
  },
  meta: {
    app: {
      title: 'Kostenloses Tierlaute Spiele Für Kinder',
      description: 'Kostenlose Lern-Mini-Spiele für Kinder mit über 50 Tierlauten. Lass dein Kind sein/ ihr Gedächtnis trainieren, in dem es ausgewählte Tiere in einer zunehmend komplexeren Reihenfolge richtig ordnet (inklusive Blind-Modus), die Tiere in einem lustigen Quiz nur anhand ihrer Laute identifiziert oder einfach nur die Tierlaute abspielt und genießt. Jetzt ausprobieren!',
      social: {
        title: 'Tier Spiele',
        description: 'Jetzt ausprobieren! - Kostenlose Lern-Mini-Spiele für Kinder mit über 50 Tierlauten.',
      },
    },
    animalsInOrder: {
      start: {
        title: 'Tiere ordnen',
        description: 'Merke dir eine zufällige Reihenfolge von Tieren die sich jede Runde um eins erhöht. Kostenloses Lern-Mini-Spiel für Kinder mit über 50 Tierlauten. Jetzt ausprobieren!',
      },
      highscore: {
        title: 'Tieren Ordnen - Bestenliste',
        description: `Bestenliste von den schnellsten Spielern von 'Tiere Ordnen'.`,
      },
    },
    animalsInOrderBlind: {
      start: {
        title: 'Tiere Ordnen - Blind-Modus',
        description: 'Merke dir eine zufällige Reihenfolge von Tieren nur anhand ihres Lautes die sich jede Runde um eins erhöht. Kostenloses Lern-Mini-Spiel für Kinder mit über 50 Tierlauten. Jetzt ausprobieren!',
      },
      highscore: {
        title: 'Tiere Ordnen - Blind-Modus - Bestenliste',
        description: `Bestenliste von den schnellsten Spielern von 'Tiere Ordnen - Blind-Modus'.`,
      },
    },
    animalQuiz: {
      start: {
        title: 'Tier Quiz',
        description: 'Höre einen Tierlaut und rate zu welchem Tier dieser Laut gehört. Kostenloses Lern-Mini-Spiel für Kinder mit über 50 Tierlauten. Jetzt ausprobieren!',
      },
      highscore: {
        title: 'Tier Quiz - Bestenliste',
        description: `Bestenliste der schnellsten Spieler von 'Tier Quiz'.`,
      },
    },
    animalSounds: {
      start: {
        title: 'Tierlaute',
        description: `Höre über 50 Tierlaute kostenlos an. Eine unterhaltsame und spielerische Möglichkeit deinem Kind die verschiedenen Tierlaute beizubringen. Jetzt ausprobieren!`,
      },
    },
  },
  404: {
    title: 'Seite nicht gefunden',
  },
};

