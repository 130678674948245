module.exports = {
  'african-grey-parrot': 'african-grey-parrot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buffalo',
  butterfly: 'butterfly',
  camel: 'camel',
  cat: 'cat',
  chicken: 'chicken',
  cow: 'cow',
  crow: 'crow',
  dinosaur: 'dinosaur',
  dog: 'dog',
  dove: 'dove',
  duck: 'duck',
  elephant: 'elephant',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'frog',
  giraffe: 'giraffe',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'hedgehog',
  hippopotamus: 'hippopotamus',
  horse: 'horse',
  'humpback-whale': 'humpback-whale',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'leopard',
  lion: 'lion',
  lizard: 'lizard',
  moose: 'moose',
  otter: 'otter',
  owl: 'owl',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'pig',
  rabbit: 'rabbit',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'rhinoceros',
  robin: 'robin',
  rooster: 'rooster',
  scorpion: 'scorpion',
  shark: 'shark',
  sheep: 'sheep',
  swan: 'swan',
  tiger: 'tiger',
  turkey: 'turkey',
  wolf: 'wolf',
  yak: 'yak',
  zebra: 'zebra',
};
