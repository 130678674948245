module.exports = {
  'african-grey-parrot': 'afrikan-çal-towuk',
  alligator: 'alligator',
  alpaca: 'alpaka',
  anteater: 'anteater',
  antelope: 'antilope',
  ape: 'maýmyn',
  bat: 'bat',
  bee: 'ary',
  'bowhead-whale': 'ýaý-kit',
  buffalo: 'buffalo',
  butterfly: 'kebelek',
  camel: 'düýe',
  cat: 'pişik',
  chicken: 'towuk',
  cow: 'sygyr',
  crow: 'garga',
  dinosaur: 'dinozawr',
  dog: 'it',
  dove: 'kepderi',
  duck: 'ördek',
  elephant: 'pil',
  falcon: 'laçyn',
  ferret: 'ferret',
  frog: 'gurbaga',
  giraffe: 'irairaf',
  'guinea-pig': 'gwineýa-doňuz',
  hedgehog: 'kirpi',
  hippopotamus: 'gippopotamus',
  horse: 'at',
  'humpback-whale': 'gümürtik-kit',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-aagondarha',
  leopard: 'gaplaň',
  lion: 'arslan',
  lizard: 'keýik',
  moose: 'keýik',
  otter: 'otter',
  owl: 'baýguş',
  panda: 'panda',
  penguin: 'pingwin',
  pig: 'doňuz',
  rabbit: 'towşan',
  raccoon: 'ýangyç',
  rat: 'syçan',
  rattlesnake: 'çybyn',
  rhinoceros: 'kerpiç',
  robin: 'robin',
  rooster: 'horaz',
  scorpion: 'garpyz',
  shark: 'akula',
  sheep: 'goýun',
  swan: 'akja',
  tiger: 'gaplaň',
  turkey: 'hindi',
  wolf: 'möjek',
  yak: 'ýak',
  zebra: 'zebra',
};
