const animalsInOrder = {
  id: 'animals_in_order',
  preview: require('@/assets/images/game_cards/animals-in-order.png'),
  title: 'Animals In Order',
  text: `
  4 animals are highlighted randomly. Memorize the order and repeat it as it's shown.
  Each round the order increases making it more and more difficult to memorize it.
  You can choose which animals to play with and how many rounds the game should last.
  `,
};

const animalsInOrderBlind = {
  id: 'animals_in_order_blind',
  preview: require('@/assets/images/game_cards/animals-in-order-blind.png'),
  title: 'Animals In Order Blind Mode',
  text: `
  The same idea as 'Animals In Order' just with a small twist - You are blind:
  You can only hear the animals but not see them while the order is played. You have to memorize them solely based on their sounds.
  `,
};

const animalQuiz = {
  id: 'animal_quiz',
  preview: require('@/assets/images/game_cards/animal-quiz.jpg'),
  title: 'Animal Quiz',
  text: `
  Listen to a variety of different animal sounds and guess to which animal the sound belongs.
  `,
};

const animalSounds = {
  id: 'animal_sounds',
  preview: require('@/assets/images/game_cards/animal-sounds.png'),
  title: 'Animal Sounds',
  text: `
  This is not a game but simply gives you the opportunity to play all animal sounds without interruption at your will.
  `,
};

export default [
  animalsInOrder,
  animalsInOrderBlind,
  animalQuiz,
  animalSounds,
];
