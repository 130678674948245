module.exports = {
  'african-grey-parrot': 'афро-серый-попугай',
  alligator: 'аллигатор',
  alpaca: 'альпака',
  anteater: 'муравьед',
  antelope: 'антилопа',
  ape: 'обезьяна',
  bat: 'летучая мышь',
  bee: 'пчела',
  'bowhead-whale': 'гренландский кит',
  buffalo: 'буйвол',
  butterfly: 'бабочка',
  camel: 'верблюд',
  cat: 'Кот',
  chicken: 'курица',
  cow: 'корова',
  crow: 'ворона',
  dinosaur: 'динозавр',
  dog: 'собака',
  dove: 'голубь',
  duck: 'утка',
  elephant: 'слон',
  falcon: 'сокол',
  ferret: 'хорек',
  frog: 'лягушка',
  giraffe: 'жирафа',
  'guinea-pig': 'морская свинка',
  hedgehog: 'еж',
  hippopotamus: 'бегемот',
  horse: 'лошадь',
  'humpback-whale': 'Горбатый кит',
  hyena: 'гиена',
  'komodo-dragon': 'Дракон Комодо',
  leopard: 'леопард',
  lion: 'лев',
  lizard: 'ящерица',
  moose: 'лось',
  otter: 'выдра',
  owl: 'сова',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'свинья',
  rabbit: 'кролик',
  raccoon: 'енот',
  rat: 'крыса',
  rattlesnake: 'гремучая змея',
  rhinoceros: 'носорог',
  robin: 'Робин',
  rooster: 'петух',
  scorpion: 'скорпион',
  shark: 'акула',
  sheep: 'овец',
  swan: 'лебедь',
  tiger: 'тигр',
  turkey: 'Турция',
  wolf: 'волк',
  yak: 'як',
  zebra: 'зебра',
};
