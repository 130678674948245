module.exports = {
  'african-grey-parrot': 'Afrikos pilka papūga',
  alligator: 'aligatorius',
  alpaca: 'alpaka',
  anteater: 'skruzdėlynas',
  antelope: 'antilopė',
  ape: 'beždžionė',
  bat: 'šikšnosparnis',
  bee: 'bitė',
  'bowhead-whale': 'banginis banginis',
  buffalo: 'buivolai',
  butterfly: 'drugelis',
  camel: 'kupranugaris',
  cat: 'katė',
  chicken: 'višta',
  cow: 'karvė',
  crow: 'varna',
  dinosaur: 'dinozauras',
  dog: 'šuo',
  dove: 'balandis',
  duck: 'antis',
  elephant: 'dramblys',
  falcon: 'sakalas',
  ferret: 'šeškas',
  frog: 'varlė',
  giraffe: 'žirafa',
  'guinea-pig': 'jūrų kiaulytė',
  hedgehog: 'ežiukas',
  hippopotamus: 'begemotas',
  horse: 'arklys',
  'humpback-whale': 'kuprotas-banginis',
  hyena: 'hiena',
  'komodo-dragon': 'komodo-drakonas',
  leopard: 'leopardas',
  lion: 'liūtas',
  lizard: 'driežas',
  moose: 'briedis',
  otter: 'ūdra',
  owl: 'pelėda',
  panda: 'panda',
  penguin: 'pingvinas',
  pig: 'kiaulė',
  rabbit: 'triušis',
  raccoon: 'meškėnas',
  rat: 'žiurkė',
  rattlesnake: 'barškuolė',
  rhinoceros: 'raganosis',
  robin: 'robin',
  rooster: 'gaidys',
  scorpion: 'skorpionas',
  shark: 'ryklys',
  sheep: 'avis',
  swan: 'gulbė',
  tiger: 'tigras',
  turkey: 'kalakutiena',
  wolf: 'vilkas',
  yak: 'jakas',
  zebra: 'zebras',
};
