module.exports = {
  'african-grey-parrot': 'afrika-gri-papağan',
  alligator: 'timsah',
  alpaca: 'alpaka',
  anteater: 'karıncayiyen',
  antelope: 'antilop',
  ape: 'maymun',
  bat: 'yarasa',
  bee: 'arı',
  'bowhead-whale': 'bowhead-balina',
  buffalo: 'manda',
  butterfly: 'kelebek',
  camel: 'deve',
  cat: 'kedi',
  chicken: 'tavuk',
  cow: 'inek',
  crow: 'karga',
  dinosaur: 'dinozor',
  dog: 'köpek',
  dove: 'güvercin',
  duck: 'ördek',
  elephant: 'fil',
  falcon: 'şahin',
  ferret: 'gelincik',
  frog: 'kurbağa',
  giraffe: 'zürafa',
  'guinea-pig': 'kobay',
  hedgehog: 'kirpi',
  hippopotamus: 'su aygırı',
  horse: 'at',
  'humpback-whale': 'kambur balina',
  hyena: 'sırtlan',
  'komodo-dragon': 'komodo-ejderhası',
  leopard: 'leopar',
  lion: 'aslan',
  lizard: 'kertenkele',
  moose: 'geyik',
  otter: 'su samuru',
  owl: 'baykuş',
  panda: 'panda',
  penguin: 'penguen',
  pig: 'domuz',
  rabbit: 'tavşan',
  raccoon: 'rakun',
  rat: 'sıçan',
  rattlesnake: 'çıngıraklı yılan',
  rhinoceros: 'gergedan',
  robin: 'robin',
  rooster: 'horoz',
  scorpion: 'akrep',
  shark: 'köpekbalığı',
  sheep: 'koyun',
  swan: 'kuğu',
  tiger: 'kaplan',
  turkey: 'türkiye',
  wolf: 'kurt',
  yak: 'yak',
  zebra: 'zebra',
};
