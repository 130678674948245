/* eslint-disable no-shadow */
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import difference from 'lodash/difference';
import shuffle from 'lodash/shuffle';
import findKey from 'lodash/findKey';

const utils = {
  isEqual,
  orderBy,
  find,
  difference,
  shuffle,
  findKey,
};

export default {
  install: (app) => {
    Object.defineProperty(app.config.globalProperties, 'utils', { value: utils });

    app.provide('utils', utils);
  },
};

