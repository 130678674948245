module.exports = {
  'african-grey-parrot': 'africano-cinza-papagaio',
  alligator: 'jacaré',
  alpaca: 'alpaca',
  anteater: 'tamanduá',
  antelope: 'antílope',
  ape: 'macaco',
  bat: 'morcego',
  bee: 'abelha',
  'bowhead-whale': 'baleia-da-cara',
  buffalo: 'búfalo',
  butterfly: 'borboleta',
  camel: 'camelo',
  cat: 'gato',
  chicken: 'galinha',
  cow: 'vaca',
  crow: 'corvo',
  dinosaur: 'dinossauro',
  dog: 'cachorro',
  dove: 'pomba',
  duck: 'pato',
  elephant: 'elefante',
  falcon: 'falcão',
  ferret: 'furão',
  frog: 'sapo',
  giraffe: 'girafa',
  'guinea-pig': 'cobaia',
  hedgehog: 'ouriço',
  hippopotamus: 'hipopótamo',
  horse: 'cavalo',
  'humpback-whale': 'baleia-jubarte',
  hyena: 'hiena',
  'komodo-dragon': 'dragão de komodo',
  leopard: 'leopardo',
  lion: 'leão',
  lizard: 'lagarto',
  moose: 'alce',
  otter: 'lontra',
  owl: 'coruja',
  panda: 'panda',
  penguin: 'pinguim',
  pig: 'porco',
  rabbit: 'coelho',
  raccoon: 'guaxinim',
  rat: 'rato',
  rattlesnake: 'cascavel',
  rhinoceros: 'rinoceronte',
  robin: 'robin',
  rooster: 'galo',
  scorpion: 'escorpião',
  shark: 'tubarão',
  sheep: 'ovelha',
  swan: 'cisne',
  tiger: 'tigre',
  turkey: 'peru',
  wolf: 'lobo',
  yak: 'iaque',
  zebra: 'zebra',
};
