module.exports = {
  'african-grey-parrot': 'afrikaans-grys-papegaai',
  alligator: 'krokodil',
  alpaca: 'alpaca',
  anteater: 'miervreter',
  antelope: 'bok',
  ape: 'aap',
  bat: 'vlermuis',
  bee: 'by',
  'bowhead-whale': 'boogkopwalvis',
  buffalo: 'buffel',
  butterfly: 'vlinder',
  camel: 'kameel',
  cat: 'kat',
  chicken: 'hoender',
  cow: 'koei',
  crow: 'kraai',
  dinosaur: 'dinosourus',
  dog: 'hond',
  dove: 'duif',
  duck: 'eend',
  elephant: 'olifant',
  falcon: 'valk',
  ferret: 'fret',
  frog: 'padda',
  giraffe: 'kameelperd',
  'guinea-pig': 'marmot',
  hedgehog: 'krimpvarkie',
  hippopotamus: 'seekoei',
  horse: 'perd',
  'humpback-whale': 'boggelrugwalvis',
  hyena: 'hiëna',
  'komodo-dragon': 'komodo-draak',
  leopard: 'luiperd',
  lion: 'leeu',
  lizard: 'akkedis',
  moose: 'eland',
  otter: 'otter',
  owl: 'uil',
  panda: 'panda',
  penguin: 'pikkewyn',
  pig: 'vark',
  rabbit: 'konyn',
  raccoon: 'wasbeer',
  rat: 'rot',
  rattlesnake: 'ratelslang',
  rhinoceros: 'renoster',
  robin: 'robin',
  rooster: 'haan',
  scorpion: 'skerpioen',
  shark: 'haai',
  sheep: 'skape',
  swan: 'swaan',
  tiger: 'tier',
  turkey: 'kalkoen',
  wolf: 'wolf',
  yak: 'yak',
  zebra: 'sebra',
};
