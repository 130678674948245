<template>
  <h2 class="text-white text-center pt-4 pb-6" data-test-id="animal_selection__title">
    {{ $t('components.animalsInOrder.title', {
      amount: animalCounter,
      more: animalCounter === 4 ? '' : `${$t('components.animalsInOrder.more')}`,
      animal: $t('components.animalsInOrder.animal', animalCounter)
    }) }}
  </h2>
  <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-12">
    <Animal
      v-for="animal in animals" :key="animal.name"
      :animal="animal"
      show-name />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Animal from '@/components/shared/animal';
import animalList from '@/config/animalList';

export default {
  name: 'AnimalSelection',
  components: {
    Animal,
  },
  setup() {
    const store = useStore();

    const animalSelection = computed(() => store.getters['animalsInOrder/animalSelection']);
    const animalCounter = computed(() => 4 - animalSelection.value.length);

    return {
      animals: animalList,
      animalCounter,
    };
  },
};
</script>
