<template>
  <div
    @click="selectable ? selectAnimal(animal.name) : playerMove(animal.name)"
    :class="{disabled: disabled}"
    class="flex flex-col items-center"
    data-test-id="animal__container"
    :id="animal.name">
    <img
      class="hover-transition rounded-lg cursor-pointer"
      :class="{
        hidden: isBlindMode,
        selectable: selectable,
        highlightable: highlightable,
        selected: selectable ? animalSelection.includes(animal.name) : false,
        [animal.data.color]: highlight === animal.name,
        'highlight-animal': highlight === animal.name,
        ['bg-red-600']: showError === animal.name,
        ['transform-gpu scale-125 pt-10 pb-12']: scale,
        ['pulse']: pulse
      }"
      :src="animal.data.image"
      :alt="animalNameTranslated"
      data-test-id="animal__img">
    <audio
      preload
      :src="animal.data.audio"
      :ref="el => { if (el) audio[animal.name] = el }"
      data-test-id="animal__audio" />
    <div
      v-if="showName"
      class="capitalize z-10 text-center"
      :class="{
        [animalNameTextColor]: animalNameTextColor,
        [pulseTextColor]: pulse
      }"
      data-test-id="animal__name">
      {{ animalNameTranslated }}
    </div>
  </div>
</template>

<script>
import {
  ref, toRefs, computed, watch, onBeforeUpdate, inject,
} from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Animal',
  props: {
    animal: {
      type: Object,
      default: () => {},
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    highlightable: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: String,
      default: '',
    },
    showError: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isBlindMode: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    animalNameTextColor: {
      type: String,
      default: 'text-white',
    },
    pulseTextColor: {
      type: String,
      default: 'text-black',
    },
    scale: {
      type: Boolean,
      default: false,
    },
    pulseAnimation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:playerMove'],
  setup(props, { emit }) {
    const store = useStore();
    const { findKey } = inject('utils');

    const audio = ref([]);
    const pulse = ref(false);
    const { animal } = toRefs(props);

    const { pulseAnimation, highlight } = toRefs(props);
    const animalSelection = computed(() => store.getters['animalsInOrder/animalSelection']);
    const animalTranslations = computed(() => store.getters['animalTranslations/translations']);
    const animalLanguage = computed(() => store.getters['user/animalLanguage']);
    const countryCode = computed(() => findKey(animalTranslations.value, animalLanguage.value));
    const animalNameTranslated = computed(() => animalTranslations.value[countryCode.value][animalLanguage.value][animal.value.name]);

    const setAnimalSelection = (name) => store.dispatch('animalsInOrder/setAnimalSelection', name);
    const selectAnimal = (name) => {
      setAnimalSelection(name);
      audio.value[name].play();
    };
    const playerMove = (name) => {
      emit('update:playerMove', name);
      audio.value[name].play();

      if (pulseAnimation.value) {
        pulse.value = true;

        let duration = Math.ceil(audio.value[name].duration);
        duration = duration > 3 ? 4500 : 3000;

        setTimeout(() => {
          pulse.value = false;
        }, duration);
      }
    };

    watch(highlight, (name) => {
      if (audio.value[name]) {
        audio.value[name].play();
      }
    });

    onBeforeUpdate(() => {
      audio.value = [];
    });


    return {
      audio,
      pulse,
      animalSelection,
      animalNameTranslated,
      selectAnimal,
      playerMove,
    };
  },
};
</script>


<style scoped>
.disabled {
  pointer-events: none;
  touch-action: none;
}

.pulse {
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background: #f5e5c4;
    box-shadow: 0 0 0 0 #ffdc95;
    border-radius: 50%;
  }

  50% {
    background: #ffdc95;
    box-shadow: 0 0 50px 10px #ffdc95;
    border-radius: 50%;
  }

  100% {
    background: #f5e5c4;
    box-shadow: 0 0 0 0 #ffdc95;
    border-radius: 50%;
  }
}

.selectable,
.highlightable {
  @apply hover:bg-green-600;
}

.selected {
  @apply bg-green-600;
}

.gold {
  @apply bg-yellow-400;
}

.green {
  @apply bg-green-400;
}

.dark-green {
  @apply bg-green-600;
}

.purple {
  @apply bg-purple-400;
}

.blue {
  @apply bg-blue-400;
}
</style>
