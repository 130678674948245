module.exports = {
  'african-grey-parrot': 'afrikansk-grå-papegoja',
  alligator: 'alligator',
  alpaca: 'alpacka',
  anteater: 'anteater',
  antelope: 'antilop',
  ape: 'apa',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buffel',
  butterfly: 'fjäril',
  camel: 'kamel',
  cat: 'katt',
  chicken: 'kyckling',
  cow: 'ko',
  crow: 'kråka',
  dinosaur: 'dinosaurie',
  dog: 'hund',
  dove: 'duva',
  duck: 'anka',
  elephant: 'elefant',
  falcon: 'falk',
  ferret: 'iller',
  frog: 'groda',
  giraffe: 'giraff',
  'guinea-pig': 'marsvin',
  hedgehog: 'igelkott',
  hippopotamus: 'flodhäst',
  horse: 'häst',
  'humpback-whale': 'knölval',
  hyena: 'hyena',
  'komodo-dragon': 'komododraken',
  leopard: 'leopard',
  lion: 'lejon',
  lizard: 'ödla',
  moose: 'älg',
  otter: 'otter',
  owl: 'uggla',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'gris',
  rabbit: 'kanin',
  raccoon: 'tvättbjörn',
  rat: 'råtta',
  rattlesnake: 'skallerorm',
  rhinoceros: 'noshörning',
  robin: 'robin',
  rooster: 'tupp',
  scorpion: 'skorpion',
  shark: 'haj',
  sheep: 'får',
  swan: 'svan',
  tiger: 'tiger',
  turkey: 'kalkon',
  wolf: 'varg',
  yak: 'yak',
  zebra: 'zebra',
};
