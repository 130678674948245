module.exports = {
  'african-grey-parrot': 'afraic-liath-parrot',
  alligator: 'ailigéadar',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antalóp',
  ape: 'ape',
  bat: 'bat',
  bee: 'bee',
  'bowhead-whale': 'bowhead-whale',
  buffalo: 'buabhall',
  butterfly: 'féileacán',
  camel: 'camel',
  cat: 'cat',
  chicken: 'sicín',
  cow: 'bó',
  crow: 'beanna',
  dinosaur: 'dineasáir',
  dog: 'madra',
  dove: 'colm',
  duck: 'lacha',
  elephant: 'eilifint',
  falcon: 'fabhcún',
  ferret: 'ferret',
  frog: 'frog',
  giraffe: 'sioráf',
  'guinea-pig': 'muc ghuine',
  hedgehog: 'gráinneog',
  hippopotamus: 'hippopotamus',
  horse: 'capall',
  'humpback-whale': 'humpback-whale',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-dragon',
  leopard: 'liopard',
  lion: 'leon',
  lizard: 'laghairt',
  moose: 'moose',
  otter: 'dobharchú',
  owl: 'ulchabhán',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'muc',
  rabbit: 'coinín',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'srónbheannach',
  robin: 'robin',
  rooster: 'rooster',
  scorpion: 'scairp',
  shark: 'siorc',
  sheep: 'caora',
  swan: 'eala',
  tiger: 'tíogair',
  turkey: 'turcaí',
  wolf: 'mac tíre',
  yak: 'yak',
  zebra: 'séabra',
};
