module.exports = {
  'african-grey-parrot': 'african-grey-parrot',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'антеатр',
  antelope: 'антилопа',
  ape: 'маймун',
  bat: 'бат',
  bee: 'занбӯри асал',
  'bowhead-whale': 'камонвар-наҳанг',
  buffalo: 'буйвол',
  butterfly: 'шапалак',
  camel: 'шутур',
  cat: 'гурба',
  chicken: 'мурғ',
  cow: 'гов',
  crow: 'зоғ',
  dinosaur: 'динозавр',
  dog: 'саг',
  dove: 'кабӯтар',
  duck: 'мурғобӣ',
  elephant: 'фил',
  falcon: 'шоҳин',
  ferret: 'парранда',
  frog: 'қурбоққа',
  giraffe: 'жираф',
  'guinea-pig': 'хуки гвинея',
  hedgehog: 'хорпушт',
  hippopotamus: 'гиппопотамус',
  horse: 'асп',
  'humpback-whale': 'кампал-наҳанг',
  hyena: 'гиена',
  'komodo-dragon': 'комодо-аждаҳо',
  leopard: 'паланг',
  lion: 'шер',
  lizard: 'сусмор',
  moose: 'муй',
  otter: 'оттар',
  owl: 'бум',
  panda: 'панда',
  penguin: 'пингвин',
  pig: 'хук',
  rabbit: 'харгӯш',
  raccoon: 'раққоса',
  rat: 'каламуш',
  rattlesnake: 'мотуранг',
  rhinoceros: 'керикҳо',
  robin: 'робин',
  rooster: 'хурӯс',
  scorpion: 'каждум',
  shark: 'акула',
  sheep: 'гӯсфанд',
  swan: 'Свон',
  tiger: 'паланг',
  turkey: 'Туркия',
  wolf: 'гург',
  yak: 'як',
  zebra: 'зебра',
};
