import de from './de';
import en from './en';
import ru from './ru';
import es from './es';
import it from './it';
import fr from './fr';
import tr from './tr';
import hi from './hi';
import ko from './ko';
import el from './el';
import sv from './sv';
import ka from './ka';
import bg from './bg';
import ro from './ro';
import pl from './pl';
import ar from './ar';
import zh from './zh';
import ja from './ja';
import da from './da';
import hu from './hu';
import is from './is';
import fi from './fi';
import cs from './cs';
import id from './id';
import hr from './hr';
import nl from './nl';
import vi from './vi';
import fa from './fa';
import he from './he';
import pt from './pt';
import uk from './uk';
import no from './no';
import mi from './mi';
import zu from './zu';
import af from './af';
import la from './la';
import sk from './sk';
import ms from './ms';
import pa from './pa';
import th from './th';
import ga from './ga';
import lt from './lt';
import lb from './lb';
import mg from './mg';
import lv from './lv';
import mn from './mn';
import be from './be';
import kk from './kk';
import mk from './mk';
import sl from './sl';
import sr from './sr';
import az from './az';
import sq from './sq';
import hy from './hy';
import tg from './tg';
import tt from './tt';
import cy from './cy';
import gd from './gd';
import uz from './uz';
import tk from './tk';
import sm from './sm';


const animalTranslations = {
  de: { de },
  en: { en },
  ru: { ru, tt },
  es: { es },
  it: { it, la },
  fr: { fr },
  tr: { tr },
  in: { hi, pa },
  kr: { ko },
  gr: { el },
  se: { sv },
  ge: { ka },
  bg: { bg },
  ro: { ro },
  pl: { pl },
  ae: { ar },
  cn: { zh },
  jp: { ja },
  dk: { da },
  hu: { hu },
  is: { is },
  fi: { fi },
  cz: { cs },
  id: { id },
  hr: { hr },
  nl: { nl },
  vn: { vi },
  ir: { fa },
  il: { he },
  pt: { pt },
  ua: { uk },
  no: { no },
  nz: { mi },
  za: { zu, af },
  sk: { sk },
  my: { ms },
  th: { th },
  ie: { ga },
  lt: { lt },
  lu: { lb },
  mg: { mg },
  lv: { lv },
  mn: { mn },
  by: { be },
  kz: { kk },
  mk: { mk },
  sl: { sl },
  sr: { sr },
  az: { az },
  al: { sq },
  am: { hy },
  tj: { tg },
  gb: { cy, gd },
  uz: { uz },
  tm: { tk },
  ws: { sm },
};

export const animalLanguagesCountryCodes = []
  .concat([], ...Object.values(animalTranslations)
    .map((lang) => Object.keys(lang)));

export default animalTranslations;
