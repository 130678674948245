module.exports = {
  'african-grey-parrot': 'afrikanesch-gro-Papagei',
  alligator: 'Alligator',
  alpaca: 'Alpaka',
  anteater: 'Anteater',
  antelope: 'Antilope',
  ape: 'Ape',
  bat: 'Fliedermaus',
  bee: 'Bee',
  'bowhead-whale': 'Béiwen-Wal',
  buffalo: 'Buffalo',
  butterfly: 'Päiperlek',
  camel: 'Kamel',
  cat: 'Kaz',
  chicken: 'Poulet',
  cow: 'Kou',
  crow: 'Kräiz',
  dinosaur: 'Dinosaurier',
  dog: 'Hond',
  dove: 'Dove',
  duck: 'Ent',
  elephant: 'Elefant',
  falcon: 'Falk',
  ferret: 'Frett',
  frog: 'Fräsch',
  giraffe: 'Giraff',
  'guinea-pig': 'Meerschwein',
  hedgehog: 'Kéiseker',
  hippopotamus: 'Hippopotamus',
  horse: 'Päerd',
  'humpback-whale': 'Bumpelwal',
  hyena: 'Hyena',
  'komodo-dragon': 'Komodo-Draach',
  leopard: 'Leopard',
  lion: 'Léiw',
  lizard: 'Eidechs',
  moose: 'Moose',
  otter: 'otter',
  owl: 'owl',
  panda: 'panda',
  penguin: 'Pinguin',
  pig: 'Schwäin',
  rabbit: 'Kaninchen',
  raccoon: 'Raccoon',
  rat: 'Rat',
  rattlesnake: 'Rattlesnake',
  rhinoceros: 'Noshorn',
  robin: 'Robin',
  rooster: 'Hunn',
  scorpion: 'Skorpioun',
  shark: 'Haische',
  sheep: 'Schof',
  swan: 'Schwan',
  tiger: 'Tiger',
  turkey: 'Truthahn',
  wolf: 'Wollef',
  yak: 'Yak',
  zebra: 'Zebra',
};
