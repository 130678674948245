module.exports = {
  'african-grey-parrot': 'afriško-siva papiga',
  alligator: 'aligator',
  alpaca: 'alpaka',
  anteater: 'mravojed',
  antelope: 'antilopa',
  ape: 'opica',
  bat: 'netopir',
  bee: 'čebela',
  'bowhead-whale': 'kitov kit',
  buffalo: 'bivol',
  butterfly: 'metulj',
  camel: 'kamela',
  cat: 'mačka',
  chicken: 'piščanec',
  cow: 'krava',
  crow: 'vrana',
  dinosaur: 'dinozaver',
  dog: 'pes',
  dove: 'golob',
  duck: 'raca',
  elephant: 'slon',
  falcon: 'sokol',
  ferret: 'dihur',
  frog: 'žaba',
  giraffe: 'žirafa',
  'guinea-pig': 'morski prašiček',
  hedgehog: 'jež',
  hippopotamus: 'povodni konj',
  horse: 'konj',
  'humpback-whale': 'grbavi kit',
  hyena: 'hijena',
  'komodo-dragon': 'komodo-zmaj',
  leopard: 'leopard',
  lion: 'lev',
  lizard: 'kuščar',
  moose: 'los',
  otter: 'vidra',
  owl: 'sova',
  panda: 'panda',
  penguin: 'pingvin',
  pig: 'prašič',
  rabbit: 'zajec',
  raccoon: 'rakun',
  rat: 'podgana',
  rattlesnake: 'klopotača',
  rhinoceros: 'nosorog',
  robin: 'robin',
  rooster: 'petelin',
  scorpion: 'škorpijon',
  shark: 'morski pes',
  sheep: 'ovca',
  swan: 'labod',
  tiger: 'tiger',
  turkey: 'puran',
  wolf: 'volk',
  yak: 'jak',
  zebra: 'zebra',
};
