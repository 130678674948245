<template>
  <transition name="slide-fade">
    <div
      v-if="show"
      @click="show = false"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      class="fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm z-30"
      data-test-id="snackbar__container">
      <label
        for="footertoast"
        class="
        close
        cursor-pointer
        flex
        items-start
        justify-between
        w-full
        p-2
        h-24
        rounded
        shadow-lg
        text-white"
        :class="color"
        data-test-id="snackbar__label">
        {{ text }}
        <svg
          class="fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18">
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
        </svg>
      </label>
    </div>
  </transition>
</template>

<script>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Snackbar',
  setup() {
    const store = useStore();
    const show = ref(false);
    const timer = ref(null);

    const text = computed(() => store.getters['current/snackbar'].text || '');
    const color = computed(() => store.getters['current/snackbar'].color || 'bg-red-500');

    const scheduleClose = (timeout) => {
      timer.value = setTimeout(() => { show.value = false; }, timeout);
    };

    const onMouseEnter = () => clearTimeout(timer.value);
    const onMouseLeave = () => scheduleClose(1000);

    const unsubscribeAction = store.subscribeAction({
      after: (action) => {
        if (action.type === 'current/setSnackbar') {
          show.value = true;
          scheduleClose(3000);
        }
      },
    });

    onBeforeUnmount(() => unsubscribeAction());

    return {
      show,
      onMouseEnter,
      onMouseLeave,
      text,
      color,
    };
  },
};
</script>

<style>
.slide-fade-enter-active {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-fade-leave-active {
  animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}

</style>
