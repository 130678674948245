module.exports = {
  'african-grey-parrot': 'african-grey-grey',
  alligator: 'alligator',
  alpaca: 'alpaca',
  anteater: 'anteater',
  antelope: 'antelope',
  ape: 'ape',
  bat: 'pekapeka',
  bee: 'bee',
  'bowhead-whale': 'bowhead-tohor',
  buffalo: 'buffalo',
  butterfly: 'butterfly',
  camel: 'camel',
  cat: 'ngeru',
  chicken: 'heihei',
  cow: 'kau',
  crow: 'tangi',
  dinosaur: 'mokoweri',
  dog: 'kurī',
  dove: 'kukupa',
  duck: 'pārera',
  elephant: 'arewhana',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'frog',
  giraffe: 'giraffe',
  'guinea-pig': 'guinea-pig',
  hedgehog: 'hedgehog',
  hippopotamus: 'hippopotamus',
  horse: 'hoiho',
  'humpback-whale': 'tohorā-tohorā',
  hyena: 'hyena',
  'komodo-dragon': 'komodo-tarakona',
  leopard: 'reparo',
  lion: 'raiona',
  lizard: 'mokomoko',
  moose: 'moose',
  otter: 'otter',
  owl: 'ruru',
  panda: 'panda',
  penguin: 'penguin',
  pig: 'poaka',
  rabbit: 'rapeti',
  raccoon: 'raccoon',
  rat: 'rat',
  rattlesnake: 'rattlesnake',
  rhinoceros: 'rhinoceros',
  robin: 'robin',
  rooster: 'tikaokao',
  scorpion: 'kopiona',
  shark: 'mango',
  sheep: 'hipi',
  swan: 'swan',
  tiger: 'tiger',
  turkey: 'korukoru',
  wolf: 'wuruhi',
  yak: 'yak',
  zebra: 'zebra',
};
