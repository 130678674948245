<template>
  {{ $t('components.stopWatch.time') }}: {{ timeToString(timer) }}
</template>

<script>
import {
  ref, toRefs, watch, onBeforeUnmount,
} from 'vue';
import { timeToString } from '@/helper/timeToString';

export default {
  name: 'StopWatch',
  props: {
    start: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:time'],
  setup(props, { emit }) {
    const startTime = ref(null);
    const timerInterval = ref(null);
    const elapsedTime = ref(0);
    const timer = ref(0);

    const { start } = toRefs(props);

    watch(start, (shouldStart) => {
      if (shouldStart) {
        startTime.value = Date.now() - elapsedTime.value;
        timerInterval.value = setInterval(() => {
          elapsedTime.value = Date.now() - startTime.value;
          timer.value = elapsedTime.value;
        }, 1000);
      } else {
        emit('update:time', elapsedTime.value);
        clearInterval(timerInterval.value);
      }
    }, { immediate: true });

    onBeforeUnmount(() => {
      clearInterval(timerInterval.value);
    });

    return {
      timer,
      timeToString,
    };
  },
};
</script>
