module.exports = {
  'african-grey-parrot': '아프리카 회색 앵무새',
  alligator: '악어',
  alpaca: '알파카',
  anteater: '개미핥기',
  antelope: '영양',
  ape: '원숭이',
  bat: '박쥐',
  bee: '벌',
  'bowhead-whale': '활고래',
  buffalo: '버팔로',
  butterfly: '나비',
  camel: '낙타',
  cat: '고양이',
  chicken: '닭',
  cow: '소',
  crow: '까마귀',
  dinosaur: '공룡',
  dog: '개',
  dove: '비둘기',
  duck: '오리',
  elephant: '코끼리',
  falcon: '매',
  ferret: '족제비',
  frog: '개구리',
  giraffe: '기린',
  'guinea-pig': '기니피그',
  hedgehog: '고슴도치',
  hippopotamus: '하마',
  horse: '말',
  'humpback-whale': '혹등고래',
  hyena: '하이에나',
  'komodo-dragon': '코모도드래곤',
  leopard: '표범',
  lion: '사자',
  lizard: '도마뱀',
  moose: '무스',
  otter: '수달',
  owl: '올빼미',
  panda: '팬더',
  penguin: '펭귄',
  pig: '돼지',
  rabbit: '토끼',
  raccoon: '너구리',
  rat: '쥐',
  rattlesnake: '방울뱀',
  rhinoceros: '코뿔소',
  robin: '로빈',
  rooster: '수탉',
  scorpion: '전갈',
  shark: '상어',
  sheep: '양',
  swan: '백조',
  tiger: '호랑이',
  turkey: '칠면조',
  wolf: '늑대',
  yak: '야크',
  zebra: '얼룩말',
};
