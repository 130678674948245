<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 bg-white text-black hover:bg-yellow-100 hover-transition p-4 rounded cursor-pointer"
    :class="{not_playable: !playable}">
    <div class="order-2 md:order-1 flex justify-center">
      <img
        class="rounded"
        :alt="title"
        :src="imgSrc"
        data-test-id="game_card__preview_img">
    </div>
    <div class="flex flex-col px-4 order-1 md:order-2">
      <h1 data-test-id="game_card__title">
        {{ title }}
      </h1>
      <p class="pt-4" data-test-id="game_card__text">
        {{ text }}
      </p>
      <p class="h-full flex items-end justify-center text-2xl pt-4 pb-8">
        <span v-if="playable" class="underline" data-test-id="game_card__playable">
          {{ $t('components.gameCard.playNow') }}
        </span>
        <span v-else>{{ $t('components.gameCard.comingSoon') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameCard',
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    playable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.not_playable {
  @apply bg-gray-200 pointer-events-none;
}
</style>
