module.exports = {
  'african-grey-parrot': 'الببغاء الأفريقي الرمادي',
  alligator: 'التمساح',
  alpaca: 'الألبكة',
  anteater: 'آكل النمل',
  antelope: 'الظباء',
  ape: 'القرد',
  bat: 'الخفاش',
  bee: 'النحل',
  'bowhead-whale': 'الحوت المقوس الرأس',
  buffalo: 'الجاموس',
  butterfly: 'الفراشة',
  camel: 'الجمل',
  cat: 'قط',
  chicken: 'دجاج',
  cow: 'بقرة',
  crow: 'الغراب',
  dinosaur: 'الديناصور',
  dog: 'الكلب',
  dove: 'حمامة',
  duck: 'بطة',
  elephant: 'فيل',
  falcon: 'الصقر',
  ferret: 'النمس',
  frog: 'الضفدع',
  giraffe: 'الزرافة',
  'guinea-pig': 'خنزير غينيا',
  hedgehog: 'القنفذ',
  hippopotamus: 'فرس النهر',
  horse: 'الحصان',
  'humpback-whale': 'الحوت الأحدب',
  hyena: 'الضبع',
  'komodo-dragon': 'تنين كومودو',
  leopard: 'النمر',
  lion: 'الأسد',
  lizard: 'السحلية',
  moose: 'موس',
  otter: 'قضاعة',
  owl: 'بومة',
  panda: 'باندا',
  penguin: 'البطريق',
  pig: 'الخنزير',
  rabbit: 'الأرنب',
  raccoon: 'الراكون',
  rat: 'الجرذ',
  rattlesnake: 'الأفعى الجرسية',
  rhinoceros: 'وحيد القرن',
  robin: 'روبن',
  rooster: 'الديك',
  scorpion: 'العقرب',
  shark: 'القرش',
  sheep: 'الأغنام',
  swan: 'بجعة',
  tiger: 'نمر',
  turkey: 'تركيا',
  wolf: 'الذئب',
  yak: 'الياك',
  zebra: 'الحمار الوحشي',
};
