module.exports = {
  'african-grey-parrot': 'loro-gris-africano',
  alligator: 'cocodrilo',
  alpaca: 'alpaca',
  anteater: 'oso hormiguero',
  antelope: 'antílope',
  ape: 'simio',
  bat: 'murciélago',
  bee: 'abeja',
  'bowhead-whale': 'ballena de Groenlandia',
  buffalo: 'búfalo',
  butterfly: 'mariposa',
  camel: 'camello',
  cat: 'gato',
  chicken: 'pollo',
  cow: 'vaca',
  crow: 'cuervo',
  dinosaur: 'dinosaurio',
  dog: 'perro',
  dove: 'paloma',
  duck: 'pato',
  elephant: 'elefante',
  falcon: 'halcón',
  ferret: 'hurón',
  frog: 'rana',
  giraffe: 'jirafa',
  'guinea-pig': 'conejillo de indias',
  hedgehog: 'erizo',
  hippopotamus: 'hipopótamo',
  horse: 'caballo',
  'humpback-whale': 'ballena jorobada',
  hyena: 'hiena',
  'komodo-dragon': 'dragón de komodo',
  leopard: 'leopardo',
  lion: 'león',
  lizard: 'lagarto',
  moose: 'alce',
  otter: 'nutria',
  owl: 'búho',
  panda: 'panda',
  penguin: 'pingüino',
  pig: 'cerdo',
  rabbit: 'conejo',
  raccoon: 'mapache',
  rat: 'rata',
  rattlesnake: 'serpiente de cascabel',
  rhinoceros: 'rinoceronte',
  robin: 'petirrojo',
  rooster: 'gallo',
  scorpion: 'escorpión',
  shark: 'tiburón',
  sheep: 'oveja',
  swan: 'cisne',
  tiger: 'tigre',
  turkey: 'pavo',
  wolf: 'lobo',
  yak: 'yak',
  zebra: 'cebra',
};
