<template>
  <GameBack @click="$router.push(pathTo.home)" />
  <h1 class="text-white text-center pt-4">
    {{ $t('gameList.animals_in_order.title') }}
  </h1>
  <AnimalSelection />
  <GameConfiguration
    :show="animalSelectionComplete"
    :game-route="pathTo.animals_in_order__game"
    @cancel="resetAnimalSelection" />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import GameBack from '@/components/shared/game_back';
import AnimalSelection from '@/components/animals_in_order/animal_selection';
import GameConfiguration from '@/components/shared/game_configuration';
import { pathTo } from '@/plugins/router/helper';

export default {
  name: 'AnimalsInOrderStart',
  components: {
    GameBack,
    AnimalSelection,
    GameConfiguration,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    useMeta({
      title: t('meta.animalsInOrder.start.title'),
      meta: [
        { name: 'description', content: t('meta.animalsInOrder.start.description') },
      ],
    });

    const animalSelectionComplete = computed(() => store.getters['animalsInOrder/animalSelectionComplete']);
    const resetAnimalSelection = () => store.dispatch('animalsInOrder/resetAnimalSelection');

    resetAnimalSelection();

    return {
      pathTo,
      animalSelectionComplete,
      resetAnimalSelection,
    };
  },
};
</script>
