module.exports = {
  'african-grey-parrot': '非洲灰鸚鵡',
  alligator: '鱷魚',
  alpaca: '羊駝',
  anteater: '食蟻獸',
  antelope: '羚羊',
  ape: '猿',
  bat: '蝙蝠',
  bee: '蜜蜂',
  'bowhead-whale': '弓頭鯨',
  buffalo: '水牛',
  butterfly: '蝴蝶',
  camel: '駱駝',
  cat: '貓',
  chicken: '雞',
  cow: '牛',
  crow: '烏鴉',
  dinosaur: '恐龍',
  dog: '狗',
  dove: '鴿子',
  duck: '鴨子',
  elephant: '大象',
  falcon: '獵鷹',
  ferret: '雪貂',
  frog: '青蛙',
  giraffe: '長頸鹿',
  'guinea-pig': '豚鼠',
  hedgehog: '刺猬',
  hippopotamus: '河馬',
  horse: '馬',
  'humpback-whale': '座頭鯨',
  hyena: '鬣狗',
  'komodo-dragon': '科莫多龍',
  leopard: '豹',
  lion: '獅子',
  lizard: '蜥蜴',
  moose: '駝鹿',
  otter: '水獺',
  owl: '貓頭鷹',
  panda: '熊貓',
  penguin: '企鵝',
  pig: '豬',
  rabbit: '兔子',
  raccoon: '浣熊',
  rat: '老鼠',
  rattlesnake: '響尾蛇',
  rhinoceros: '犀牛',
  robin: '知更鳥',
  rooster: '公雞',
  scorpion: '蝎子',
  shark: '鯊魚',
  sheep: '羊',
  swan: '天鵝',
  tiger: '老虎',
  turkey: '火雞',
  wolf: '狼',
  yak: '犛牛',
  zebra: '斑馬',
};
