<template>
  <GamePlay
    :title="$t('gameList.animals_in_order.title')"
    :reset-game-route="pathTo.animals_in_order__start"
    :highscore-route="pathTo.animals_in_order__highscore"
    :document-path="HIGHSCORE__ANIMALS_IN_ORDER" />
</template>

<script>
import GamePlay from '@/components/animals_in_order/gameplay';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { pathTo } from '@/plugins/router/helper';
import { HIGHSCORE__ANIMALS_IN_ORDER } from '@/config/firestore';

export default {
  name: 'AnimalsInOrderGame',
  components: {
    GamePlay,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t('meta.animalsInOrder.start.title'),
      meta: [
        { name: 'description', content: t('meta.animalsInOrder.start.description') },
      ],
    });

    return {
      pathTo,
      HIGHSCORE__ANIMALS_IN_ORDER,
    };
  },
};
</script>
