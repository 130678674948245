<template>
  <metainfo>
    <template #title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="flex justify-end">
    <LanguageSelector />
  </div>
  <router-view />
  <Snackbar />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import LanguageSelector from '@/components/language_selector';
import Snackbar from '@/components/shared/basic/Snackbar';

export default {
  components: {
    LanguageSelector,
    Snackbar,
  },
  setup() {
    const { t } = useI18n();
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const ogImageUrl = `${baseUrl}${require('@/assets/images/game_cards/animals-in-order.png')}`;

    useMeta({
      title: t('meta.app.title'),
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
        { name: 'robots', content: 'index, follow' },
        { name: 'description', content: t('meta.app.description') },
        { name: 'og:title', property: 'og:title', content: t('meta.app.social.title') },
        { name: 'og:description', property: 'og:description', content: t('meta.app.social.description') },
        { name: 'og:url', property: 'og:url', content: baseUrl },
        { name: 'og:type', property: 'og:type', content: 'game' },
        { name: 'og:image', property: 'og:image', content: ogImageUrl },
        { name: 'og:locale', property: 'og:locale', content: 'en-US' },
        { name: 'twitter:title', property: 'twitter:title', content: t('meta.app.social.title') },
        { name: 'twitter:description', property: 'twitter:description', content: t('meta.app.social.description') },
        { name: 'twitter:url', property: 'twitter:url', content: baseUrl },
        { name: 'twitter:image', property: 'twitter:image', content: ogImageUrl },
      ],
      link: [
        { rel: 'favicon', href: 'favicon.ico' },
        { rel: 'canonical', href: baseUrl },
      ],
      noscript: [
        { innerHTML: 'This website requires JavaScript.' },
      ],
    });
  },
};
</script>
