module.exports = {
  'african-grey-parrot': 'აფრიკული ნაცრისფერი თუთიყუში',
  alligator: 'ალიგატორი',
  alpaca: 'ალპაკა',
  anteater: 'ჭიანჭველა',
  antelope: 'ანტილოპა',
  ape: 'მაიმუნი',
  bat: 'ღამურა',
  bee: 'ფუტკარი',
  'bowhead-whale': 'მშვილდოსანი-ვეშაპი',
  buffalo: 'კამეჩი',
  butterfly: 'პეპელა',
  camel: 'აქლემი',
  cat: 'კატა',
  chicken: 'ქათამი',
  cow: 'ძროხა',
  crow: 'ყორანი',
  dinosaur: 'დინოზავრი',
  dog: 'ძაღლი',
  dove: 'მტრედი',
  duck: 'იხვი',
  elephant: 'სპილო',
  falcon: 'falcon',
  ferret: 'ferret',
  frog: 'frog',
  giraffe: 'ჟირაფი',
  'guinea-pig': 'ზღვის ღორი',
  hedgehog: 'ზღარბი',
  hippopotamus: 'ჰიპოპოტამი',
  horse: 'ცხენი',
  'humpback-whale': 'კეხი-ვეშაპი',
  hyena: 'ჰიენა',
  'komodo-dragon': 'კომოდო-დრაკონი',
  leopard: 'ლეოპარდი',
  lion: 'ლომი',
  lizard: 'ხვლიკი',
  moose: 'ლოსი',
  otter: 'ვიტრა',
  owl: 'ბუ',
  panda: 'პანდა',
  penguin: 'პინგვინი',
  pig: 'ღორი',
  rabbit: 'კურდღელი',
  raccoon: 'ენოტი',
  rat: 'ვირთხა',
  rattlesnake: 'ვირთხა',
  rhinoceros: 'მარტორქა',
  robin: 'რობინი',
  rooster: 'მამალი',
  scorpion: 'მორიელი',
  shark: 'ზვიგენი',
  sheep: 'ცხვარი',
  swan: 'გედი',
  tiger: 'ვეფხვი',
  turkey: 'თურქეთი',
  wolf: 'მგელი',
  yak: 'იაკი',
  zebra: 'ზებრა',
};
