module.exports = {
  'african-grey-parrot': 'афрыканска-шэры папугай',
  alligator: 'алігатар',
  alpaca: 'альпака',
  anteater: 'мурашкаед',
  antelope: 'антылопа',
  ape: 'малпа',
  bat: 'лятучая мыш',
  bee: 'пчала',
  'bowhead-whale': 'грэнландскі кіт',
  buffalo: 'буйвал',
  butterfly: 'матылёк',
  camel: 'вярблюд',
  cat: 'котка',
  chicken: 'курыца',
  cow: 'карова',
  crow: 'варона',
  dinosaur: 'дыназаўр',
  dog: 'сабака',
  dove: 'голуб',
  duck: 'качка',
  elephant: 'слон',
  falcon: 'сокал',
  ferret: 'тхор',
  frog: 'жаба',
  giraffe: 'жыраф',
  'guinea-pig': 'марская свінка',
  hedgehog: 'вожык',
  hippopotamus: 'бегемот',
  horse: 'конь',
  'humpback-whale': 'гарбаты кіт',
  hyena: 'гіена',
  'komodo-dragon': 'камадо-цмок',
  leopard: 'леапард',
  lion: 'леў',
  lizard: 'яшчарка',
  moose: 'лось',
  otter: 'выдра',
  owl: 'сава',
  panda: 'панда',
  penguin: 'пінгвін',
  pig: 'свіння',
  rabbit: 'трус',
  raccoon: 'янот',
  rat: 'пацук',
  rattlesnake: 'грымучая змяя',
  rhinoceros: 'насарог',
  robin: 'малінаўка',
  rooster: 'певень',
  scorpion: 'скарпіён',
  shark: 'акула',
  sheep: 'авечка',
  swan: 'лебедзь',
  tiger: 'тыгр',
  turkey: 'індычка',
  wolf: 'воўк',
  yak: 'як',
  zebra: 'зебра',
};
